<div class="container-fluid animated fadeIn">
    <div class="row">
        <div class="col-12 my-2 d-lg-none">
            <button type="button" class="btn btn-block"
                    (click)="isCollapsed = ! isCollapsed" [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample"
            >{{ isCollapsed == true ? 'Mostrar filtros' : 'Ocultar filtros' }}
            </button>
        </div>
        <!-- Si cambiamos el 'lg' de col-lg-3 habra que cambiar isComputerLayout()-->
        <div id="filtros" class="filtros col-lg-3 col-sm-12 align-self-start" #collapse="ngbCollapse"
             [(ngbCollapse)]="isCollapsed" #filtros>
            <div class="form-group mt-2">
                <label>Arquitecta</label>
                <ng-select [items]="arquitectas"
                           bindLabel="name"
                           bindValue="id"
                           placeholder="Seleccione una arquitecta"
                           [(ngModel)]="obraFilter.arquitecta"
                           (change)="applyFilters()">
                </ng-select>
            </div>
            <div class="form-group mt-2">
                <label>Equipo de trabajo</label>
                <ng-select [items]="modos"
                           bindLabel="nombre"
                           placeholder="Seleccione equipo de trabajo"
                           [(ngModel)]="obraFilter.modo"
                           (change)="applyFilters()">
                </ng-select>
            </div>
            <div class="form-group mt-2">
                <label>Comunidad autónoma</label>
                <ng-select [items]="comunidadesAutonomas"
                           bindLabel="name"
                           bindValue="key"
                           placeholder="Seleccione comunidad autónoma"
                           [(ngModel)]="obraFilter.comunidadAutonoma"
                           (change)="applyFilters()">
                </ng-select>
            </div>
            <hr class="separador-filtros"/>
            <div class="form-group">
                <label for="fechaInicio">Fecha inicio</label>
                <div class="custom-slider">
                    <ngx-slider id="fechaInicio" [(value)]="obraFilter.fechaSlider.minValue"
                                [(highValue)]="obraFilter.fechaSlider.maxValue" [options]="sliderOptions"
                                (valueChange)="applyFilters()" (highValueChange)="applyFilters()">
                    </ngx-slider>
                </div>
            </div>
            <div class="form-group mt-2">
                <label>Período histórico</label>
                <ng-select [items]="periodosHistoricos"
                           bindLabel="nombreAnos"
                           placeholder="Seleccione período histórico"
                           multiple="true"
                           [(ngModel)]="obraFilter.periodoHistorico"
                           (change)="updateAndApplyFiltersPeriodoHistorico()">
                </ng-select>
            </div>
            <div class="form-group mt-2">
                <label>Fotógrafa</label>
                <ng-select [items]="fotografas"
                           bindLabel="name"
                           bindValue="id"
                           placeholder="Seleccione una fotógrafa"
                           [(ngModel)]="obraFilter.fotografa"
                           (change)="applyFilters()">
                </ng-select>
            </div>
            <div class="form-group mt-2">
                <label>Tipo producción</label>
                <ng-select [items]="tiposProduccion2"
                           bindLabel="name"
                           bindValue="key"
                           placeholder="Seleccione tipo producción"
                           multiple="true"
                           [(ngModel)]="obraFilter.tipoProduccion2"
                           (change)="applyFilters()">
                </ng-select>
            </div>
            <div class="form-group mt-2">
                <label>Tipo producción 2</label>
                <ng-select [items]="tiposProduccion1"
                           bindLabel="name"
                           bindValue="key"
                           placeholder="Seleccione tipo producción"
                           multiple="true"
                           [(ngModel)]="obraFilter.tipoProduccion1"
                           (change)="applyFilters()">
                </ng-select>
            </div>
            <button class="btn btn-block my-4" (click)="removeFilters()">Limpiar filtros</button>
            <div class="row border-top border-dark mt-2">
                <div class="col-12 mt-2">
                    <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}"><b>{{contadorArquitectas}}</b>
                    </h4>
                    <h4 class="my-1" style="display: inline"> Arquitectas</h4>
                </div>
                <div class="col-12">
                    <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}"><b>{{contadorObras}}</b></h4>
                    <h4 class="my-1" style="display: inline"> Obras</h4>
                </div>
                <div class="mb-2 col-12">
                    <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}"><b>{{contadorFotografas}}</b>
                    </h4>
                    <h4 class="my-1" style="display: inline"> Fotógrafas</h4>
                </div>
            </div>
        </div>
        <div class="map px-0 col-lg-9 col-sm-12">
            <div id="map" #map></div>
        </div>
    </div>
</div>
