import {NgModule} from '@angular/core';
import {
    NgbAlertModule,
    NgbDateAdapter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule, NgbPopoverModule, NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {NgSelectConfig, NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Principal} from 'app/security/principal.service';
import {CustomFormsModule} from 'ngx-custom-validators';
import {FwFilesConfig, FwFilesModule} from 'fw-files';
import {
    FwAuthModule,
    FwCommonConfig,
    FwCommonModule,
    FwFormsConfig,
    FwFormsModule,
    FwPaginationConfig,
    FwPaginationModule,
    FwPrincipalService,
    FwServiceWorkerServiceConfig,
    NgbDateStringAdapter
} from 'fw-core';
import {AlertErrorComponent} from 'app/shared/alert-error.component';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {SearchSelectComponent} from "app/shared/search-select/search-select.component";
import {CommonTooltipComponent} from "app/shared/common-tooltip/common-tooltip.component";

@NgModule({
    imports: [FormsModule, CustomFormsModule, FwCommonModule, FwAuthModule, FwFilesModule,
        CommonModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, FwFormsModule,
        FwPaginationModule,
        RouterModule, NgSelectModule, NgOptionHighlightModule, NgbAlertModule, NgbPopoverModule, NgbTooltipModule],
    declarations: [AlertErrorComponent, SearchSelectComponent, CommonTooltipComponent],
    providers: [
        {provide: NgbDateAdapter, useClass: NgbDateStringAdapter},
        {provide: FwPrincipalService, useClass: Principal}],
    exports: [FwFormsModule, CustomFormsModule, AlertErrorComponent,
        NgSelectModule, NgOptionHighlightModule, FormsModule, CommonModule, FwAuthModule, FwCommonModule,
        NgbDatepickerModule, NgbModalModule, NgbDropdownModule, NgbAlertModule, FwPaginationModule, SearchSelectComponent]
})
export class SharedModule {

    constructor(private config: NgSelectConfig, private commonConfig: FwCommonConfig,
                private selectConfig: NgSelectConfig,
                private paginationConfig: FwPaginationConfig, private formsConfig: FwFormsConfig,
                private filesConfig: FwFilesConfig,
                private workerServiceConfig: FwServiceWorkerServiceConfig) {

        this.config.notFoundText = $localize`Not found`;

        this.selectConfig.notFoundText = $localize`Sin resultados`;
        this.selectConfig.typeToSearchText = $localize`Escriba para buscar`;
        this.selectConfig.loadingText = $localize`Cargando...`;
        this.selectConfig.clearAllText = $localize`Limpiar`;

        this.commonConfig.i18n = {
            confirmOk: $localize`Sí`,
            confirmCancel: $localize`No`,
            confirmTitle: $localize`Confirmación de borrado`,
            connectionErrorToastMessage: $localize`No se ha podido establecer conexión con el servidor, comprueba que tienes acceso a internet. Si el problema persiste contacta con el administrador.`
        };

        this.paginationConfig.pageSize = 20;
        this.paginationConfig.i18n = {
            showingFrom: $localize`Mostrando resultados desde`,
            showingTo: $localize`hasta`,
            showingOf: $localize`de`,
            loadMore: $localize`Cargar más`
        };

        this.formsConfig.date.showCalendarOnFocus = true;
        this.formsConfig.i18n = {
            save: $localize`Guardar`,
            accept: $localize`Aceptar`,
            edit: $localize`Editar`,
            cancel: $localize`Cancelar`,
            saveContinue: $localize`Guardar y continuar`,
            emptyList: $localize`No hay elementos`,
            selectAllOptions: $localize`-- Todas las opciones --`,
            validationDefault: $localize`Error de validación: `,
            validationEmail: $localize`El email no es válido`,
            validationRequired: $localize`Campo requerido`,
            validationInvalidPattern: $localize`Patrón incorrecto`,
            validationMaxLength1: $localize`Debe tener como máximo `,
            validationMaxLength2: $localize` caracteres`,
            validationMinLength1: $localize`Debe tener como mínimo `,
            validationMinLength2: $localize` caracteres`,
            booleanNo: $localize`No`,
            booleanYes: $localize`Sí`,
        };

        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlFicheroTemporal = 'api/files';
        this.filesConfig.urlImagenDefault = 'content/img/default.jpg';
        this.filesConfig.i18n = {
            fileSelect: $localize`Seleccionar archivo`,
            fileNoFileSelected: $localize`Ningún archivo seleccionado`,
            fileDownload: $localize`Descargar`,
            fileDelete: $localize`Eliminar`,
            fileLoading: $localize`Cargando...`,
            imageLoading: $localize`Cargando...`,
            imageChange: $localize`Cambiar`,
            imageCropTitle: $localize`Coloca y redimensiona la imagen`,
            accept: $localize`Aceptar`,
            cancel: $localize`Cancelar`,
            genericError: $localize`Se ha producido un error`
        };

        this.workerServiceConfig.i18n = {
            toastHeaderText: $localize`Hay una nueva versión de la aplicación.`,
            toastBodyText: $localize`¿Quieres refrescar la página para obtenerla?`,
            updateLabel: $localize`¡Actualizar!`
        };

    }
}
