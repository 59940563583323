import {Injectable} from '@angular/core';
import {FotografiaDTO} from 'app/apina/apina-api';

@Injectable({providedIn: 'root'})
export class FotografiaUtilService {

    private URL_IMAGEN_DEFAULT = 'content/img/default.jpg';

    getFotografia(f: FotografiaDTO): string {
        return f && f.fotografia ?
            'api/fotografia/descargar/' + f.fotografia.id + '/' + f.fotografia.name :
            this.URL_IMAGEN_DEFAULT;
    }
}
