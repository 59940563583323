import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, Routes} from '@angular/router';
import {PublicNavComponent} from 'app/public/public-nav/public-nav.component';
import {HomeComponent} from 'app/public/home/home.component';
import {GridFotografiasComponent} from 'app/public/fotografias/grid-fotografias/grid-fotografias.component';
import {Injectable} from '@angular/core';
import {Dictionary} from 'fw-core';
import {ObraResourceEndpoint} from 'app/apina/apina-api';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TipoProduccionResolve implements Resolve<Dictionary<any>> {
    constructor(private router: Router, private obraResource: ObraResourceEndpoint) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Dictionary<any> | Observable<Dictionary<any>> | Promise<Dictionary<any>> {
        return this.obraResource.obtenerTiposProduccion();
    }
}

@Injectable({providedIn: 'root'})
export class TipoProduccion2Resolve implements Resolve<Dictionary<any>> {
    constructor(private router: Router, private obraResource: ObraResourceEndpoint) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Dictionary<any> | Observable<Dictionary<any>> | Promise<Dictionary<any>> {
        return this.obraResource.obtenerTiposProduccion2();
    }
}

@Injectable({providedIn: 'root'})
export class ComunidadAutonomaResolve implements Resolve<Dictionary<any>> {
    constructor(private router: Router, private obraResource: ObraResourceEndpoint) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Dictionary<any> | Observable<Dictionary<any>> | Promise<Dictionary<any>> {
        return this.obraResource.obtenerComunidadesAutonomas();
    }
}

@Injectable({providedIn: 'root'})
export class EstatusResolve implements Resolve<Dictionary<any>> {
    constructor(private router: Router, private obraResource: ObraResourceEndpoint) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Dictionary<any> | Observable<Dictionary<any>> | Promise<Dictionary<any>> {
        return this.obraResource.obtenerEstatus();
    }
}

@Injectable({providedIn: 'root'})
export class FotografiasGridResolve implements Resolve<Dictionary<any>> {
    constructor(private router: Router, private obraResource: ObraResourceEndpoint) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Dictionary<any> | Observable<Dictionary<any>> | Promise<Dictionary<any>> {
        return this.obraResource.obtenerEstatus();
    }
}

export const PUBLIC_ROUTES: Routes = [
    {
        path: '',
        component: PublicNavComponent,
        children: [
            {
                path: 'mapa',
                component: HomeComponent,
                resolve: {
                    tiposProduccion: TipoProduccionResolve,
                    tiposProduccion2: TipoProduccion2Resolve,
                    comunidadesAutonomas: ComunidadAutonomaResolve,
                    estatus: EstatusResolve
                }
            },
            {
                path: 'fotografias',
                component: GridFotografiasComponent,
                resolve: {
                    tiposProduccion: TipoProduccionResolve,
                    tiposProduccion2: TipoProduccion2Resolve,
                    comunidadesAutonomas: ComunidadAutonomaResolve,
                    estatus: EstatusResolve
                }
            },
        ]
    }
];
