export const USER_MENU = [
    {
        name: 'Inicio',
        url: '',
        icon: 'fas fa-home'
    },
    {
        title: true,
        name: 'Administración'
    },
    {
        name: 'Obras',
        url: '/admin/obras',
        icon: 'fas fa-sharp fa-regular fa-building'
    },
    {
        name: 'Personas',
        url: '/admin/personas',
        icon: 'fas fa-users'
    }
];
