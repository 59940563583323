import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Principal} from 'app/security/principal.service';
import {LoginService} from 'app/auth/login/login.service';
import {AccountDTO} from 'app/apina/apina-api';
import {FwBreadcrumbsService, IBreadcrumb} from 'fw-core';

@Component({
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
    version: string;
    account: AccountDTO;
    crumbs: IBreadcrumb[];
    logos: any[];
    agradecimientos: any[];

    constructor(@Inject(LOCALE_ID) protected localeId: string,
                private loginService: LoginService,
                private principal: Principal,
                private router: Router,
                private crumbsService: FwBreadcrumbsService) {
        this.version = environment.VERSION;
    }

    ngOnInit() {
        this.crumbsService.crumbs$.subscribe(response => {
            this.crumbs = response;
        });

        this.logos = [
            {
                title: 'Fundación Española para la Ciencia y la Tecnología',
                url: 'https://www.fecyt.es/',
                img: '01_Fecyt_bn.jpg'
            },
            {title: 'Precipita', img: '02_Precipita.jpg', url: 'https://www.fecyt.es/es/tematica/precipita'},
            {title: 'Fundación Arquia', img: '03_logotipoArquia_c.jpg', url: 'https://fundacion.arquia.com/'},
            {
                title: 'Instituto de Patrimonio y Humanidades Universidad de Zaragoza',
                img: '04_logo-iph_c.jpg',
                url: 'https://iphunizar.com/'
            },
            {title: 'ArcelorMittal', img: '05_Arcelor.jpg', url: 'https://spain.arcelormittal.com/'},
            {title: 'Ayuntamiento de Pedrola', img: '06_Ayuntamiento_Pedrola.jpeg', url: 'https://www.pedrola.es/'},
            {title: 'Equal Saree', img: '07_EqualSaree.jpg', url: 'https://equalsaree.org/es/'},
            {title: 'LAAAB Laboratorio de Aragón Gobierno Abierto', img: '08_LAAB.jpg', url: 'https://www.laaab.es/'},
            {
                title: 'Colegio Oficial de Arquitectos de Aragón',
                img: '09_COAAragon.jpg',
                url: 'https://www.coaaragon.es/'
            },
            {
                title: 'COAC | COL·LEGI D ARQUITECTES DE CATALUNYA',
                img: '09_COACataluna.jpg',
                url: 'https://www.arquitectes.cat/'
            },
            {
                title: 'Colegio Oficial de Arquitectos de Sevilla',
                img: '10_COASevilla.jpg',
                url: 'http://www.coasevilla.org/'
            },
            {
                title: 'Colegio Oficial de Arquitectos de Extremadura',
                img: '12_COAExtremadura.jpg',
                url: 'https://www.coade.org/'
            },
            {
                title: 'Colegio Oficial de Arquitectos de Asturias',
                img: '13_COAAsturias.jpg',
                url: 'https://www.coaa.es/es/'
            },
            {title: 'COAG Canarias', img: '14_COACanarias.jpg', url: 'https://coagcanarias.com/'},
            {
                title: 'COACV - Colegio de Arquitectos de la Comunidad Valenciana',
                img: '15_COAValencia.jpg',
                url: 'https://www.coacv.org/'
            },
            {
                title: 'Colegio Oficial de Arquitectos de Castilla y León este',
                img: '16_COACastillayLeon.jpg',
                url: 'https://www.coacyle.com/'
            },
            {
                title: 'Etopia',
                img: '17_etopia.jpg',
                url: 'https://etopia.es/'
            },
            {
                title: 'Enxenio',
                img: 'logo-enxeniobn.png',
                url: 'https://www.enxenio.es/'
            }
        ];

        this.agradecimientos = [
            {name: 'Matxalen Acasuso Atutxa'},
            {name: 'Eva Álvarez Isidro '},
            {name: 'Nuria Álvarez Lombardero'},
            {name: 'María Aucejo Mollà'},
            {name: 'Laura Balbona Blank'},
            {name: 'Ester Ballarín Vidal'},
            {name: 'Carmen Baras Escolá'},
            {name: 'Carmen Bermejo Lorenzo'},
            {name: 'Raquel Blanco Espiga'},
            {name: 'Mª dos Milagres Budiño Gasamáns'},
            {name: 'Juan Burgos González'},
            {name: 'Carolina Cabezas Sesé'},
            {name: 'Laura Ciriano Lambán'},
            {name: 'Vanessa Codrea Sangorzan'},
            {name: 'Juan Antonio Concepción Gómez'},
            {name: 'Ángel Cordero Ampuero'},
            {name: 'Kani Cubo Mayo'},
            {name: 'Virginia de Jorge Huertas'},
            {name: 'David Delgado Baudet'},
            {name: 'Miguel Delgado Baudet'},
            {name: 'Eduardo Delgado Orusco'},
            {name: 'Clara Dobón Ricarte'},
            {name: 'Almudena Espinosa Fernández'},
            {name: 'Ainoa Fernández Cruces'},
            {name: 'Cristina Fresnadillo'},
            {name: 'Eugenio Alfonso García de Paredes'},
            {name: 'María de los Ángeles García'},
            {name: 'David García-Asenjo Llana'},
            {name: 'Roberto Gascón Muñoz'},
            {name: 'Belén Gilarranz Gilarranz'},
            {name: 'Beatriz Gimeno Frontera'},
            {name: 'Miguel Gómez Lahera'},
            {name: 'Remedios Gómez Martín'},
            {name: 'Laura Guinda Millán'},
            {name: 'Nazareth Gutiérrez Franco'},
            {name: 'Josenia Hervás y Heras'},
            {name: 'Carlos Jiménez García'},
            {name: 'Isabel Ledesma Blasco'},
            {name: 'Emma López Bahut'},
            {name: 'Jesús López Díaz'},
            {name: 'Belinda López Mesa'},
            {name: 'Luis Miguel Lus Arana'},
            {name: 'Jorge Marín Gambón'},
            {name: 'Rosa Martín Escartín'},
            {name: 'Jorge Martin Lucas'},
            {name: 'Rafael Martínez de la Concha'},
            {name: 'Pablo Menéndez Huerta'},
            {name: 'Marta Miret Rodríguez'},
            {name: 'Yolanda Moreno Tomás'},
            {name: 'Zaida Muxí Martínez'},
            {name: 'María Novas'},
            {name: 'Marta Parra Casado'},
            {name: 'Lucía Peña Menchero'},
            {name: 'Sergio Pérez Moreno'},
            {name: 'Javier Pertíñez Moreno'},
            {name: 'Iñigo Picabea Andrés'},
            {name: 'Sonia Puente Landázuri'},
            {name: 'Lola Ribelles Buxaderas'},
            {name: 'Elena Ricarte Dobon'},
            {name: 'Carmen Rodríguez Díaz'},
            {name: 'Susana Rodríguez Dorado'},
            {name: 'Teresa Rodríguez Garagorri'},
            {name: 'Dragos Rus'},
            {name: 'Alicia Sainz Esteban'},
            {name: 'Isabel Sánchez'},
            {name: 'María Sánchez De Guillermo'},
            {name: 'Alberto Sánchez Sánchez'},
            {name: 'Patrícia Santos Pedrosa'},
            {name: 'Pilar Vicente Lázaro'},
            {name: 'Carolina Vich Redondo'},
            {name: 'Isabel Violade Pérez'}
        ];
    }
}
