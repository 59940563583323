<div class="wrapper-contenido animated fadeIn">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 my-2 d-lg-none">
                <button type="button" class="btn btn-block"
                        (click)="isCollapsed = ! isCollapsed" [attr.aria-expanded]="!isCollapsed"
                        aria-controls="collapseExample"
                >{{ isCollapsed == true ? 'Mostrar filtros' : 'Ocultar filtros' }}
                </button>
            </div>
            <div class="filtros col-lg-3 col-sm-12" #collapse="ngbCollapse"
                 [(ngbCollapse)]="isCollapsed">
                <div class="form-group mt-2">
                    <label>Fotógrafa</label>
                    <ng-select [items]="fotografas"
                               bindLabel="name"
                               bindValue="id"
                               placeholder="Seleccione una fotografa"
                               [(ngModel)]="fotografiaFiltro.idFotografa"
                               (change)="applyFilters()">
                    </ng-select>
                </div>
                <div class="form-group mt-2">
                    <label>Arquitecta</label>
                    <ng-select [items]="arquitectas"
                               bindLabel="name"
                               bindValue="id"
                               placeholder="Seleccione una arquitecta"
                               [(ngModel)]="fotografiaFiltro.idArquitecta"
                               (change)="applyFilters()">
                    </ng-select>
                </div>
                <div class="form-group mt-2">
                    <label>Equipo de trabajo</label>
                    <ng-select [items]="modos"
                               bindLabel="nombre"
                               placeholder="Seleccione equipo de trabajo"
                               [(ngModel)]="fotografiaFiltro.modo"
                               (change)="applyFilters()">
                    </ng-select>
                </div>
                <div class="form-group mt-2">
                    <label>Comunidad autónoma</label>
                    <ng-select [items]="comunidadesAutonomas"
                               bindLabel="name"
                               bindValue="key"
                               placeholder="Seleccione comunidad autónoma"
                               [(ngModel)]="fotografiaFiltro.comunidadAutonoma"
                               (change)="applyFilters()">
                    </ng-select>
                </div>
                <hr class="separador-filtros"/>
                <div class="form-group mt-2">
                    <label for="fechaInicio">Fecha inicio</label>
                    <div class="custom-slider">
                        <ngx-slider id="fechaInicio" [(value)]="fechaSlider.minValue"
                                    [(highValue)]="fechaSlider.maxValue" [options]="sliderOptions"
                                    (valueChange)="applyFilters()" (highValueChange)="applyFilters()">
                        </ngx-slider>
                    </div>
                </div>
                <div class="form-group mt-2">
                    <label>Período histórico</label>
                    <ng-select [items]="periodosHistoricos"
                               bindLabel="nombreAnos"
                               bindValue="clave"
                               placeholder="Seleccione período histórico"
                               multiple="true"
                               [(ngModel)]="fotografiaFiltro.clavesPeriodoHistorico"
                               (change)="updateAndApplyFiltersPeriodoHistorico()">
                    </ng-select>
                </div>
                <div class="form-group mt-2">
                    <label>Tipo producción</label>
                    <ng-select [items]="tiposProduccion2"
                               bindLabel="name"
                               bindValue="key"
                               placeholder="Seleccione tipo producción"
                               multiple="true"
                               [(ngModel)]="fotografiaFiltro.tiposProduccion2"
                               (change)="applyFilters()">
                    </ng-select>
                </div>
                <div class="form-group mt-2">
                    <label>Tipo producción 2</label>
                    <ng-select [items]="tiposProduccion1"
                               bindLabel="name"
                               bindValue="key"
                               placeholder="Seleccione tipo producción"
                               multiple="true"
                               [(ngModel)]="fotografiaFiltro.tiposProduccion1"
                               (change)="applyFilters()">
                    </ng-select>
                </div>
                <button class="btn btn-block my-4" (click)="removeFilters()">Limpiar filtros</button>
                <!--                <button class="btn btn-block my-4" (click)="applyFilters()">Aplicar filtros</button>-->
                <div class="row border-top border-dark mt-2">
                    <div class="col-12 mt-2">
                        <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}"><b>{{totalItems}}</b></h4>
                        <h4 class="my-1" style="display: inline"> Fotografías</h4>
                    </div>
                    <div class="col-12">
                        <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}"><b>{{contadorFotografas}}</b></h4>
                        <h4 class="my-1" style="display: inline"> Fotógrafas</h4>
                    </div>
                    <div class="col-12">
                        <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}"><b>{{contadorArquitectas}}</b></h4>
                        <h4 class="my-1" style="display: inline"> Arquitectas</h4>
                    </div>
                    <div class="mb-2 col-12">
                        <h4 class="my-1 contadores" [ngStyle]="{'width' : contadoresWidth}" ><b>{{contadorObras}}</b></h4>
                        <h4 class="my-1" style="display: inline"> Obras</h4>
                    </div>
                </div>
            </div>
            <div class="margin-top border-top border-left border-dark col-lg-9 col-sm-12">

                <!-- fw-empty-list -->
                <div *ngIf="fotografias?.length === 0" class="mt-3 alert alert-secondary" role="alert">
                    No hay fotografías
                </div>

                <div class="row p-2">
                    <div *ngFor="let fotografia of fotografias" class="col-6 col-md-3 p-1 p-lg-2">
                        <div class="h-100 img_wrap embed-responsive embed-responsive-1by1" role="button"
                             (click)="openModal(fotografia.obra.id)"
                             title="Foto de {{fotografia.obra.nombre}}">
                            <div class="embed-responsive-item border border-dark">
                                <img src="{{getFotografia(fotografia)}}"
                                     alt="Foto de {{fotografia.obra.nombre}}"
                                     class="img-center img-responsive image-cover lazyload">
                                <div class="container-text-fotografia p-2">
                                    <div class="text-center mb-0 text-fotografia">
                                        <p *ngFor="let autora of fotografia.obra.autoras" class="mb-0">
                                        {{autora.nombre}} {{autora.apellidos}} <br/>
                                         </p>
                                        {{fotografia.obra.nombre}}
                                        <br/>foto ©{{fotografia.fotografa.nombre}} {{fotografia.fotografa.apellidos}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div infinite-scroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="100" (scrolled)="onScrollDown()"
             [infiniteScrollDisabled]="isDetached">
        </div>
        <fw-pagination (pageChange)="loadAll($event)" [(order)]="orden" [class.d-none]="true"
                       [pageSize]="pageSize" [showTotalElements]="false" [totalElements]="totalItems"
                       [filter]="fotografiaFiltro"></fw-pagination>
    </div>
