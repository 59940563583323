import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ApinaConfig, ApinaEndpointContext, RequestData} from 'app/apina/apina-api';
import { BaseUrlGeneratorService, flattenConPrefijosExceptoElPrimero } from 'fw-core';

@Injectable()
export class CustomEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig,
                private baseUrlGeneratorService: BaseUrlGeneratorService) {
        super(config);

        config.baseUrl = this.baseUrlGeneratorService.generateBaseUrl('api');

        config.registerIdentitySerializer('Page');
        config.registerIdentitySerializer('Slice');
        config.registerIdentitySerializer('Sort');
        config.registerIdentitySerializer('Pageable');

        config.registerIdentitySerializer('Dictionary<any>');
    }

    request(data: RequestData): Observable<any> {

        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        // TODO esto puede hacerse con un flatten normal sin chapuzadas..
        const requestParams = data.requestParams ? flattenConPrefijosExceptoElPrimero(data.requestParams) : null;

        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any } = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null && value !== '') {
                    if (key[0] === '.') {
                        filteredParams[key.slice(1)] = value; // TODO chapuza
                    } else {
                        filteredParams[key] = value;
                    }
                }
            }
            params = new HttpParams({fromObject: filteredParams});
        }

        return this.httpClient.request(data.method, url, {params, body: data.requestBody})
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }

}
