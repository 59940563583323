<div class="animated fadeIn">
    <i class="fa fa-times btn-close-modal" title="Cerrar ventana" aria-hidden="true"
       (click)="activeModal.dismiss('Cross click')"></i>

    <div *ngIf="contenidoModal.fotografias" class="mt-4">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let f of contenidoModal.fotografias">
                <div class="picsum-img-wrapper">
                    <img type="button" src="{{getFotografia(f)}}" (click)="openPhoto(f)" alt="Foto obra"/>
                </div>
            </ng-template>
        </ngb-carousel>
        <hr class="mt-4"/>
    </div>

    <p class="mt-3"><b>Arquitectura: </b>{{contenidoModal.arquitectas}}</p>
    <p *ngIf="contenidoModal.nombre"><b>Nombre: </b> {{contenidoModal.nombre}}</p>
    <p *ngIf="contenidoModal.periodoHistorico"><b>Período histórico: </b> {{contenidoModal.periodoHistorico}}</p>
    <p *ngIf="contenidoModal.fechaInicio.length > 1"><b>Fecha inicio: </b>{{contenidoModal.fechaInicio}}</p>
    <p *ngIf="contenidoModal.fechaFin.length > 1"><b>Fecha fin: </b>{{contenidoModal.fechaFin}}</p>
    <p *ngIf="contenidoModal.equipoDeTrabajo"><b>Equipo de trabajo: </b>{{contenidoModal.equipoDeTrabajo}}</p>
    <p *ngIf="contenidoModal.comunidadAutonoma"><b>Comunidad autónoma: </b>{{contenidoModal.comunidadAutonoma}}</p>
    <p *ngIf="contenidoModal.direccion"><b>Dirección: </b>{{contenidoModal.direccion}}</p>
    <p *ngIf="contenidoModal.colaboradores.length > 1"><b>Colaboradores: </b>{{contenidoModal.colaboradores}}</p>
    <p *ngIf="contenidoModal.tipoProduccion1"><b>Tipo producción: </b>{{contenidoModal.tipoProduccion1}}</p>
    <p *ngIf="contenidoModal.estatus"><b>Estatus: </b>{{contenidoModal.estatus}}</p>
    <div *ngIf="contenidoModal.fotografa">
        <hr/>
        <p><b>Fotógrafa: </b>{{contenidoModal.fotografa}}</p>
    </div>
    <hr *ngIf="contenidoModal.referencias || contenidoModal.reconocimientos"/>
    <p *ngIf="contenidoModal.referencias"><b>Referencias:</b></p>
    <p [innerHTML]="contenidoModal.referencias"></p>
    <p *ngIf="contenidoModal.reconocimientos"><b>Reconocimientos:</b></p>
    <p [innerHTML]="contenidoModal.reconocimientos"></p>
    <div *ngIf="contenidoModal.textoCritico">
        <hr/>
        <p><b>Texto crítico:</b></p>
        <div style="display: inline" [innerHTML]="contenidoModal.textoCritico"></div>
    </div>


</div>
