import {Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AuthExpiredInterceptor} from './security/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from './security/errorhandler.interceptor';
import {DefaultLayoutComponent} from 'app/layout/default-layout/default-layout.component';
import {CustomEndpointContext} from 'app/apina/custom-endpoint-context.service';
import {ApinaEndpointContext, ApinaModule} from 'app/apina/apina-api';

import {AppRoutingModule} from 'app/app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RouteReuseStrategy} from '@angular/router';
import {AppHeaderModule} from 'app/layout/header/app-header.module';
import {AppSidebarModule} from 'app/layout/sidebar/app-sidebar.module';
import {AppFooterModule} from 'app/layout/footer/app-footer.module';
import {AppComponent} from './app.component';
import {ErrorComponent} from 'app/layout/error/error.component';
import {PageRibbonComponent} from 'app/layout/profiles/page-ribbon.component';
import {CookieModule} from 'ngx-cookie';
import {I18nModule} from 'app/i18n/i18n.module';
import {AlertErrorService, CustomReuseStrategy, FwBreadcrumbsModule, FwServiceWorkerServiceModule, LanguageService, StateStorageService} from 'fw-core';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from 'app/shared/shared.module';
import {EnumInterceptor} from 'app/security/enum.interceptor';
import {PublicLayoutComponent} from "app/layout/public-layout/public-layout.component";

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbDropdownModule,
        BrowserAnimationsModule,
        ApinaModule,
        SharedModule,
        I18nModule,
        CookieModule.forRoot(),
        FwBreadcrumbsModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        FwServiceWorkerServiceModule],
    declarations: [AppComponent, DefaultLayoutComponent,  PublicLayoutComponent, ErrorComponent, PageRibbonComponent],
    providers: [
        {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
        {provide: ApinaEndpointContext, useClass: CustomEndpointContext},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EnumInterceptor,
            multi: true,
            deps: [LanguageService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [StateStorageService, Injector]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [AlertErrorService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
