export const URL_AVATAR_DEFAULT = 'content/img/avatars/avatar-default.png';
export const URL_IMAGE_DEFAULT = 'content/img/default.jpg';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';

export const LOGIN_STATE = '/auth/login';

export const MIN_PASSWD_LENGTH = 8;

export const MIN_SEARCH_YEAR = 1965;
export const MAX_SEARCH_YEAR = 2000;

export const BLACK = '#000';
export const RED = '#D81C1B';
export const BLUE = '#177BF8';
export const PURPLE = '#6955e7';
export const ORANGE = '#F28026';
