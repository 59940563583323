import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';

/**
 * Helps handling different device sizes. Rendering a component conditionally is usualy better performance
 * wise than using bootstrap css to hide (less dirty checking).
 */
@Injectable({
    providedIn: 'root'
})
export class ResponsiveService {
    // This observer comes from  Angular CDK
    constructor(private breakpointObserver: BreakpointObserver) {
    }

    isMobileOrTablet(): boolean {
        return this.breakpointObserver.isMatched('(max-width: 991px )');
    }
}
