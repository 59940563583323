<app-header>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <a class="navbar-brand" href="http://muwo.unizar.es/" target="_blank">
        <img src="content/img/logos/MUWO.svg" class="logo-muwo" alt="Logo MUWO">
    </a>

    <div class="d-none d-lg-block">
        <ul class="nav nav-pills">
            <li class="nav-item">
                <a [routerLinkActive]="'active'" [routerLink]="['mapa']" class="nav-link text-dark">Mapa</a>
            </li>
            <li class="nav-item">
                <a [routerLinkActive]="'active'" [routerLink]="['fotografias']"
                   class="nav-link text-dark">Fotografías</a>
            </li>
            <li class="nav-item">
                <a href="http://muwo.unizar.es/colecciones" target="_blank" class="nav-link text-dark">Colecciones</a>
            </li>
            <li class="nav-item">
                <a href="http://muwo.unizar.es/participa" target="_blank" class="nav-link text-dark">Participa</a>
            </li>
        </ul>
    </div>

</app-header>


<div class="border-bottom border-dark titulo">
    <p class="col-12 font-weight-bold titulo-text">
        MAPA INTERACTIVO DIGITAL DE ARQUITECTURAS IDEADAS POR MUJERES EN ESPAÑA, 1965-2000
    </p>
</div>

<div class="app-body">
    <main class="main">
        <div class="breadcrumbs-container" *ngIf="crumbs.length > 0">
            <fw-breadcrumbs></fw-breadcrumbs>
        </div>
        <fw-router-outlet></fw-router-outlet>
    </main>
</div>

<footer class="container-fluid ">

    <div class="row border-bottom border-dark">
        <div class="col-12">
            <p class="m-3 text-center agradecimientos-text">
                ¿Colaboras para ampliar este mapa?
                <a class="color-muwo" href="http://muwo.unizar.es/participa" target="_blank"><b>¡Participa!</b></a>
            </p>
        </div>
    </div>

    <div class="row border-bottom border-dark">
        <p class="col-12 mt-3 agradecimientos-text">
            <b>Coordinación:</b> Lucía C. Pérez-Moreno y David Delgado Baudet (Universidad de Zaragoza).<br/>
            <b>Textos:</b> Serafina Amoroso (Universidad Rey Juan Carlos), David Delgado Baudet (Universidad de
            Zaragoza), Ana María Fernández García (Universidad de Oviedo), Emma López-Bahut (Universidade da Coruña),
            María Novas (TU Delft), Lucía C. Pérez-Moreno (Universidad de Zaragoza), Laura Ruiz-Morote Tramblin
            (Universidad de Zaragoza), Marta Serra Permanyer (Universitat Politècnica de Catalunya, El Vallés).<br/>
            <b>Fotografías:</b> Clàudia Aguiló, Esther Almagro, Ana Amado, Bego Amaré, María Azkarate, Carla Capdevilla,
            Maite
            Caramés, Luana Fisher,
            Elisa Gallego, Victòria Gil, Beatriz S. González, Lucía Gorostegui, Macarena Gross, María José Gurbindo,
            Kelly Hurd, Victoria Labadie, Judit Massana, María Merchante, Luisa Monleón, Erika Müller, Tanit Plana,
            María Platero, Ana Prado, María Rodríguez Cadenas, Liduvina Rojo, Rocío Romero, Simona Rota, Irene Ruiz,
            Mili Sánchez, Milena Villalba.
        </p>
    </div>
    <div class="row mb-3 border-bottom border-dark">
        <p class="col-12 mt-3 agradecimientos-text">
            <b>Citación:</b> Lucía C. Pérez-Moreno y David Delgado Baudet (coord). Mapa interactivo digital de
            arquitecturas ideadas por mujeres en España, 1965-2000. Zaragoza: Universidad de Zaragoza, 2023.
        </p>
    </div>
    <div class="row mb-3">
        <p class="col-12 agradecimientos-text">
            Este mapa ha sido posible gracias a la campaña de participación ciudadana en ciencia (PRECIPITA) coordinada
            desde el FECYT. Agradecemos el apoyo de los siguientes particulares, empresas e instituciones:
        </p>
        <section class="row1">
            <div class="col-xs-12 col-sm-6 col-lg-3 col-xl-2" *ngFor="let a of agradecimientos">
                <b>{{a.name}}</b>
            </div>
        </section>
    </div>
    <div class="row px-3 container-logos">
        <div class="col-4 col-md-2 px-1 px-lg-3 px-xl-5" *ngFor="let l of logos">
            <a [href]="l.url" target="_blank">
                <img class="embed-responsive ls-is-cached lazyloaded" src="content/img/logos/{{l.img}}"
                     alt="{{l.title}}" title="{{l.title}}" loading="lazy">
            </a>
        </div>
    </div>
    <div class="collaborators-footer row py-3">
        <div class="col-md-3">
            <a href="http://muwo.unizar.es/" target="_blank"><img
                src="content/img/logos/logo_muwo.png"
                alt="MUWO" loading="lazy" class="w-75 pb-3"></a>
            <section class="footer-text">
                <a href="http://muwo.unizar.es/" target="_blank">
                    <p>Inicio</p>
                </a>
                <a [routerLinkActive]="'active'" [routerLink]="['mapa']">
                    <p>Mapa</p>
                </a>
                <a [routerLinkActive]="'active'" [routerLink]="['fotografias']">
                    <p>Fotografías</p>
                </a>
                <a href="http://muwo.unizar.es/colecciones" target="_blank">
                    <p>Colecciones</p>
                </a>
                <a href="http://muwo.unizar.es/participa" target="_blank">
                    <p>Participa</p>
                </a>
                <a href="http://muwo.unizar.es/equipo/" target="_blank">
                    <p>Equipo</p>
                </a>
                <a href="http://muwo.unizar.es/objetivos/" target="_blank">
                    <p>Objetivos</p>
                </a>
                <a href="http://muwo.unizar.es/contacto/" target="_blank">
                    <p>Contacto</p>
                </a>
                <a href="https://www.facebook.com/MuWoArch/" target="_blank">
                    <img class="icono-rrss pl-0" src="content/img/logos/logo_facebook.png" alt="Facebook">
                </a>
                <a href="https://www.instagram.com/muwoarch/" target="_blank">
                    <img class="icono-rrss" src="content/img/logos/logo_instagram.png" alt="Instagram">
                </a>
                <a href="https://twitter.com/MuWoArch" target="_blank">
                    <img class="icono-rrss" src="content/img/logos/logo_twitter.png" alt="Twitter">
                </a>
            </section>
        </div>
        <div class="col-md-4 py-2 py-md-0">
            <section class="footer-text">
                <p><strong>Organismo: </strong>Ministerio de Ciencia e Innovación, Agencia Estatal de Investigación, Fondos Feder + Instituto de las Mujeres, Ministerio de Igualdad</p>
                <p><strong>Grupo Temático:</strong> Ciencias Sociales.</p>
                <p><strong>Área ANEP:</strong> Estudios feministas, de las mujeres y de género</p>
                <p><strong>Referencia:</strong> PGC2018-095905-A-I00 + 29/2ACT/21 + 30-2-ID22</p>
                <p><strong>Acrónimo:</strong> MuWo</p>
                <p><strong>Organismo director:</strong>&nbsp;<strong><span><a href="http://unizar.es/"
                                                                              target="_blank">Universidad de Zaragoza. </a></span><a
                    href="http://eina.unizar.es/" target="_blank">Escuela de Ingeniería y Arquitectura </a></strong></p>
                <p><strong>Otras Universidades: </strong> Universidade da Coruña, Universidad de Alcalá, Universidad de
                    Alicante, Universidad San Jorge (CESUGA), Universidad de Oviedo,Universitat Politècnica de
                    Catalunya, Universidad Politécnica de Madrid, Universidad de Málaga, Universidad de Sevilla,
                    Universidad Rey Juan Carlos.</p>
                <p><strong>Duración:</strong> 5 años</p>
            </section>
        </div>
        <div class="col-md-2">
            <section class="footer-text">
                <p><strong>Contacto:<br>
                </strong>Escuela de Ingeniería y Arquitectura<br>
                    Universidad de Zaragoza<br>
                    María de Luna, 3<br>
                    50018 – Zaragoza (Spain)</p>
            </section>
        </div>
        <div class="col-md-3 py-2 py-md-0">
            <div class="row">

                <div class="col-12 mb-2">
                    <a href="https://www.inmujeres.gob.es/" target="_blank">
                        <img src="content/img/logos/LOGO-ministerio_IMIO.png"
                             alt="" loading="lazy"
                             class="img-fluid">
                    </a>
                </div>
                <div class="col-12 my-2">
                    <a href="https://www.ciencia.gob.es/" target="_blank">
                        <img src="content/img/logos/LOGO-ministerio_CIENCIA.png"
                             alt="" loading="lazy"
                             class="img-fluid" width="59.5%">
                    </a>
                </div>
                <div class="col-12 my-2">
                    <img src="content/img/logos/LOGO-feder.png"
                         alt="" loading="lazy"
                         class="img-fluid">
                </div>
                <div class="col-12 my-2">
                    <a href="https://www.aei.gob.es/" target="_blank">
                        <img src="content/img/logos/LOGO-AgenciaEstatalInvestigacion.png"
                             alt="" loading="lazy"
                             class="d-block mx-auto"
                             style="width: 35%">
                    </a>
                </div>

                <div class="col-12 my-2">
                    <a href="https://www.unizar.es/" target="_blank">
                        <img src="content/img/logos/LOGOS-UNIVERSIDAD_ZGZ.png"
                             alt="" loading="lazy"
                             class="img-fluid">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-bar-footer row p-3 py-4">
        <b>© Proyecto I+D PGC2018-095905-A-100 + 29/2ACT/21 + 30-2-ID22</b>
    </div>

</footer>
