import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-public-nav',
    templateUrl: './public-nav.component.html',
    styleUrls: ['./public-nav.component.scss']
})
export class PublicNavComponent {

    tabs = [
        {title: 'Mapa', url: 'mapa'},
        {title: 'Fotografías', url: 'fotografias'}
    ];

    constructor(public router: Router) {
    }

}
