import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DefaultLayoutComponent} from 'app/layout/default-layout/default-layout.component';
import {ErrorComponent} from 'app/layout/error/error.component';
import {UserRouteAccessService} from 'app/security/user-route-access-service';
import {ROLE_ADMIN, ROLE_USER} from 'app/constants';
import {PUBLIC_ROUTES} from 'app/public/public.route';
import {PublicLayoutComponent} from 'app/layout/public-layout/public-layout.component';

/**
 *  Cada página pública debe tener su propio módulo lazy optimizado para conseguir el menos tamaño de
 *  build posible. En el caso de las páginas de administración o que necesitan que el usuario esté autenticado,
 *  podemos agruparlas por simplicidad ya que los tiempos de carga iniciales no son tan importantes.
 */
@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: PublicLayoutComponent,
                    children: [
                        {
                            path: '',
                            redirectTo: '/mapa',
                            pathMatch: 'full'
                        },
                        ...PUBLIC_ROUTES,
                    ]
                },
                {
                    path: 'admin',
                    component: DefaultLayoutComponent,
                    children: [
                        {
                            path: '',
                            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
                            data: {
                                authorities: [ROLE_USER, ROLE_ADMIN]
                            },
                            canActivateChild: [UserRouteAccessService],
                        }
                    ]
                },
                {
                    path: 'account',
                    component: DefaultLayoutComponent,
                    children: [
                        {
                            path: '',
                            loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
                            data: {
                                authorities: [ROLE_USER, ROLE_ADMIN]
                            },
                            canActivate: [UserRouteAccessService],
                        }
                    ]
                },
                {
                    path: 'auth',
                    component: DefaultLayoutComponent,
                    children: [
                        {
                            path: '',
                            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
                        }
                    ]

                },
                {
                    path: '**', component: ErrorComponent
                }
            ],
            {
                useHash: false, enableTracing: false, onSameUrlNavigation: 'reload'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
