import {AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';

import {
    AccountDTO,
    FotografiaDTO,
    ModoDTO,
    ModoFilter,
    ModoResourceEndpoint,
    ObraFilter,
    ObraResourceEndpoint,
    PeriodoHistoricoDTO,
    PeriodoHistoricoFilter,
    PeriodoHistoricoResourceEndpoint
} from 'app/apina/apina-api';
import {Principal} from 'app/security/principal.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Dictionary, FwBaseList} from 'fw-core';
import * as L from 'leaflet';
import {GeoJSON} from 'leaflet';
import 'leaflet.markercluster';
import {ResponsiveService} from 'app/shared/responsive.service';
import {Options} from '@angular-slider/ngx-slider';
import {BLACK, BLUE, MAX_SEARCH_YEAR, MIN_SEARCH_YEAR, ORANGE, RED} from 'app/constants';
import {ModalObraComponent} from 'app/public/modal-obra/modal-obra.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export class ContenidoModal {
    fotografias: FotografiaDTO[];
    fotografa: string;
    textoCritico: string;
    reconocimientos: string;
    referencias: string;
    estatus: '';
    tipoProduccion1: '';
    colaboradores: string;
    direccion: string;
    comunidadAutonoma: '';
    equipoDeTrabajo: '';
    fechaFin: '';
    fechaInicio: '';
    periodoHistorico: string;
    nombre: string;
    arquitectas: string;
}

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['home.component.scss']
})
export class HomeComponent extends FwBaseList implements OnInit, AfterViewInit {
    geoJSON: GeoJSON.GeoJsonObject;
    initialObraFilterCopy: ObraFilter;
    initialModoFilterCopy: ModoFilter;
    initialPeriodoHistoricoFilter: PeriodoHistoricoFilter;
    modos: ModoDTO[];
    periodosHistoricos: PeriodoHistoricoDTO[];
    arquitectas: { id: number, name: string }[];
    fotografas: { id: number, name: string }[];
    tiposProduccion1Dict: Dictionary<any>;
    tiposProduccion1: { key: string, name: string } [];
    tiposProduccion2Dict: Dictionary<any>;
    tiposProduccion2: { key: string, name: string } [];
    comunidadesAutonomasDict: Dictionary<any>;
    comunidadesAutonomas: { key: string, name: string } [];
    estaus: Dictionary<any>;
    contadorArquitectas = 0;
    contadorFotografas = 0;
    contadorObras = 0;
    wantCluster = true;
    contadoresWidth = '';

    obraFilter = {
        arquitecta: null,
        fotografa: null,
        modo: null as ModoDTO,
        comunidadAutonoma: null as string[],
        periodoHistorico: [] as Array<any>,
        tipoProduccion1: null as string[],
        tipoProduccion2: null as string[],
        fechaSlider: {
            minValue: MIN_SEARCH_YEAR,
            maxValue: MAX_SEARCH_YEAR
        }
    };
    // ngx-slider
    sliderOptions: Options = {
        floor: MIN_SEARCH_YEAR,
        ceil: MAX_SEARCH_YEAR,
        minLimit: MIN_SEARCH_YEAR,
        maxLimit: MAX_SEARCH_YEAR,
        noSwitching: true
    };

    isCollapsed = false;

    public map: L.Map;
    private markerLayer: L.Layer;

    @ViewChild('map') mapDom: ElementRef;
    @ViewChild('filtros') filtros: ElementRef;
    formatter = (e: { nombre: string }) => e.nombre;

    constructor(private principal: Principal,
                private route: ActivatedRoute, private router: Router,
                private obraResource: ObraResourceEndpoint,
                private modoResource: ModoResourceEndpoint,
                private periodoHistoricoResource: PeriodoHistoricoResourceEndpoint,
                public responsiveService: ResponsiveService,
                private modalService: NgbModal,
                private renderer: Renderer2
    ) {
        super();
    }

    ngOnInit() {
        this.tiposProduccion1Dict = this.route.snapshot.data.tiposProduccion;
        this.tiposProduccion1 = this.dictionaryToArray(this.tiposProduccion1Dict);
        this.tiposProduccion2Dict = this.route.snapshot.data.tiposProduccion2;
        this.tiposProduccion2 = this.dictionaryToArray(this.tiposProduccion2Dict);
        this.comunidadesAutonomasDict = this.route.snapshot.data.comunidadesAutonomas;
        this.comunidadesAutonomas = this.dictionaryToArray(this.comunidadesAutonomasDict);
        this.estaus = this.route.snapshot.data.estatus;
        this.initMap();
        this.loadAll([this.initialObraFilterCopy, this.initialModoFilterCopy, this.initialPeriodoHistoricoFilter]);
    }

    ngAfterViewInit() {
        this.onResize(null);
    }

    applyFilters(): void {
        const startTime = performance.now();

        const lastLayer = this.markerLayer;
        this.loadMap();
        this.map.removeLayer(lastLayer);

        const endTime = performance.now();
        //console.log(`Call to applyFilters took ${endTime - startTime} milliseconds`);
    }

    updateAndApplyFiltersPeriodoHistorico(): void {
        if (this.obraFilter.periodoHistorico.length > 0) {
            const inicioArray = this.obraFilter.periodoHistorico.map(e => parseInt(e.inicio, 10));
            const inicioInt = Math.min(...inicioArray);
            const finArray = this.obraFilter.periodoHistorico.map(e => parseInt(e.fin, 10));
            const finInt = Math.max(...finArray);
            this.obraFilter.fechaSlider.minValue = inicioInt;
            this.obraFilter.fechaSlider.maxValue = finInt;

            const newOptions: Options = Object.assign({}, this.sliderOptions);
            newOptions.minLimit = inicioInt;
            newOptions.maxLimit = finInt;
            this.sliderOptions = newOptions;
            // Cambiar color ng-slider-selection
        } else {
            this.resetSliderOptions();
        }
        this.applyFilters();
    }

    resetSliderOptions(): void {
        this.obraFilter.fechaSlider.minValue = MIN_SEARCH_YEAR;
        this.obraFilter.fechaSlider.maxValue = MAX_SEARCH_YEAR;
        const newOptions: Options = Object.assign({}, this.sliderOptions);
        newOptions.minLimit = MIN_SEARCH_YEAR;
        newOptions.maxLimit = MAX_SEARCH_YEAR;
        this.sliderOptions = newOptions;
    }

    removeFilters(): void {
        this.obraFilter = {
            arquitecta: null,
            fotografa: null,
            modo: null as ModoDTO,
            comunidadAutonoma: null as string[],
            periodoHistorico: [] as Array<any>,
            tipoProduccion1: null as string[],
            tipoProduccion2: null as string[],
            fechaSlider: {
                minValue: MIN_SEARCH_YEAR,
                maxValue: MAX_SEARCH_YEAR,
            }
        };
        this.resetSliderOptions();
        this.applyFilters();
    }

    // @ts-ignore

    loadMap(): void {
        const basicMarker = {
            radius: 6,
            color: '#fff',
            weight: 2,
            fillOpacity: 1
        };

        this.contadorArquitectas = 0;
        this.contadorObras = 0;
        const contadoraArquitecas = new Set();
        const mapArquitectas = new Map();
        const contadoraFotografas = new Set();
        const mapFotografas = new Map();
        const context = this;

        function obrasFilter(feature) {
            if (context.obraFilter.modo && context.obraFilter.modo.nombre.length > 0) {
                const featureModo = feature.properties.modo.nombre;
                const filterModo = context.obraFilter.modo.nombre.valueOf().toString();

                if (!filterModo.includes(featureModo)) {
                    return false;
                }
            }
            if (context.obraFilter.comunidadAutonoma && context.obraFilter.comunidadAutonoma.length > 0) {
                const featureComunidadAutonoma = feature.properties.comunidadAutonoma;
                const filterComunidadAutonoma = context.obraFilter.comunidadAutonoma.valueOf().toString();
                if (!filterComunidadAutonoma.includes(featureComunidadAutonoma)) {
                    return false;
                }
            }
            if (context.obraFilter.tipoProduccion2 && context.obraFilter.tipoProduccion2.length > 0) {
                const featuretipoProduccion2 = feature.properties.tipoProduccion2;
                const filtertipoProduccion2 = context.obraFilter.tipoProduccion2.valueOf().toString();
                if (!filtertipoProduccion2.includes(featuretipoProduccion2)) {
                    return false;
                }
            }
            if (context.obraFilter.tipoProduccion1 && context.obraFilter.tipoProduccion1.length > 0) {
                const featuretipoProduccion1 = feature.properties.tipoProduccion1;
                const filtertipoProduccion1 = context.obraFilter.tipoProduccion1.valueOf().toString();
                if (!filtertipoProduccion1.includes(featuretipoProduccion1)) {
                    return false;
                }
            }
            if (context.obraFilter.periodoHistorico.length > 0) {
                const featurePeriodoHistorico = feature.properties.periodoHistorico.clave;
                const filterPeriodoHistorico = context.getClavesPeriodoHistorico(context.obraFilter.periodoHistorico);
                if (!filterPeriodoHistorico.has(featurePeriodoHistorico)) {
                    return false;
                }
            }
            if (feature.properties.fechaInicio != null) {
                let fechaInicioStr: string = feature.properties.fechaInicio;
                fechaInicioStr = fechaInicioStr.substr(0, 4);
                if (/\d{4}$/.test(fechaInicioStr)) {
                    const featureFechaInicio = parseInt(fechaInicioStr, 10);
                    if (!(context.obraFilter.fechaSlider.minValue <= featureFechaInicio && featureFechaInicio <= context.obraFilter.fechaSlider.maxValue)) {
                        return false;
                    }
                }
            }
            // Añadir arquitectas a la lista del filtro
            feature.properties.autoras.forEach(arquitecta =>
                mapArquitectas.set(arquitecta.id, arquitecta.apellidos + ', ' + arquitecta.nombre)
            );
            if (context.obraFilter.arquitecta) {
                const featureAutoras = feature.properties.autoras.map(e => e.id);
                const filterAutoras = context.obraFilter.arquitecta;

                if (!featureAutoras.includes(filterAutoras)) {
                    return false;
                }
            }
            // Añadir fotografas a la lista del filtro
            if (feature.properties.fotografias) {
                feature.properties.fotografias.forEach(fotografia =>
                    mapFotografas.set(fotografia.fotografa.id, fotografia.fotografa.apellidos + ', ' + fotografia.fotografa.nombre)
                );
            }

            if (context.obraFilter.fotografa) {
                if (!feature.properties.fotografias) {
                    return false;
                }
                const featureFotografa = feature.properties.fotografias.map(e => e.fotografa.id);
                const filterFotografa = context.obraFilter.fotografa;
                if (!featureFotografa.includes(filterFotografa)) {
                    return false;
                }
            }
            return true;
        }

        const markersArray = L.geoJSON(this.geoJSON, {
            pointToLayer(feature, latlng) {
                const marker = L.circleMarker(latlng, {
                    ...basicMarker,
                    fillColor: context.getFillColor(context.getClavesPeriodoHistorico(context.obraFilter.periodoHistorico), feature.properties.periodoHistorico.clave)
                });
                marker.on('click', function(e) {
                    const modal = context.modalService.open(ModalObraComponent, {
                        windowClass: 'my-modal',
                        size: 'lg',
                        keyboard: true,
                        centered: true
                    });
                    modal.componentInstance.contenidoModal = context.getContenidoModal(feature);
                });
                return marker;
            },
            onEachFeature(feature, layer) {
                // Contadores
                feature.properties.autoras.forEach(arquitecta =>
                    contadoraArquitecas.add(arquitecta.id)
                );
                if (feature.properties.fotografias) {
                    feature.properties.fotografias.forEach(fotografia =>
                        contadoraFotografas.add(fotografia.fotografa.id)
                    );
                }
                context.contadorObras += 1;
            },
            filter: obrasFilter
        });
        if (this.wantCluster && this.obraFilter.periodoHistorico.length === 0) {
            // @ts-ignore
            const markerCluster = new L.MarkerClusterGroup({
                disableClusteringAtZoom: 13,
                spiderfyOnMaxZoom: false,
                maxClusterRadius: 15,
                // showCoverageOnHover: false, Non sei se lle gustara
                polygonOptions: {
                    color: 'black'
                },
                iconCreateFunction(cluster) {
                    let html;
                    const markers = cluster.getAllChildMarkers();
                    const childCount = cluster.getChildCount();
                    if (childCount < 10) {
                        html = '<div class="circle lightgray">' + markers.length + '</div>';
                        return L.divIcon({html, className: 'mycluster', iconSize: L.point(32, 32)});
                    } else if (childCount < 30) {
                        html = '<div class="circle grey">' + markers.length + '</div>';
                        return L.divIcon({html, className: 'mycluster', iconSize: L.point(32, 32)});
                    } else {
                        html = '<div class="circle darkgrey">' + markers.length + '</div>';
                        return L.divIcon({html, className: 'mycluster', iconSize: L.point(32, 32)});
                    }

                },
            });
            markerCluster.addLayer(markersArray);
            this.map.addLayer(markerCluster);
            this.markerLayer = markerCluster;
        } else {
            this.markerLayer = markersArray.addTo(this.map);
        }
        this.arquitectas = [];
        mapArquitectas.forEach((value, key) => this.arquitectas.push({
            id: key,
            name: value
        }));
        this.arquitectas.sort(this.alphabeticComparator);
        this.contadorArquitectas = contadoraArquitecas.size;

        this.fotografas = [];
        mapFotografas.forEach((value, key) => this.fotografas.push({
            id: key,
            name: value
        }));
        this.fotografas.sort(this.alphabeticComparator);
        this.contadorFotografas = contadoraFotografas.size;
        this.updateContadoresWidth();
    }

    loadAll([obraFilter, modoFilter, periodoHistoricoFilter]: [ObraFilter, ModoFilter, PeriodoHistoricoFilter]): void {
        this.subs.add(this.obraResource.findGeoJSON(obraFilter)
            .subscribe((res: any) => {
                this.geoJSON = res;
                this.loadMap();
            }));
        this.subs.add(this.modoResource.findAll(modoFilter)
            .subscribe((res: any) => {
                this.modos = res;
                this.modos.sort((value1, value2) => value1.nombre.localeCompare(value2.nombre));
            }));
        this.subs.add(this.periodoHistoricoResource.findAll(periodoHistoricoFilter)
            .subscribe((res: any[]) => {
                res.forEach(e => e.nombreAnos = e.nombre + ' (' + e.inicio + '-' + e.fin + ')');
                this.periodosHistoricos = res;
            }));
    }

    private initMap(): void {

        const street = L.tileLayer('https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png?api_key=8be66430-a399-4b78-a789-c782c1e9d2f9', {
            attribution: '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> <a href="https://stamen.com/" target="_blank">&copy; Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors',
        });

        const satellite = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        });

        const baseMaps = {
            'Callejero': street,
            'Satélite': satellite
        };

        this.map = L.map('map', {
            zoomControl: false,
            center: [36, -7.5],
            zoom: this.responsiveService.isMobileOrTablet() ? 5 : 6,
            minZoom: 5,
            renderer: L.canvas({tolerance: 8})
        });

        L.control.zoom({position: 'topright'}).addTo(this.map);
        L.control.layers(baseMaps).addTo(this.map);
        street.addTo(this.map);
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    login() {
        this.router.navigate(['login']);
    }

    /*
        MÉTODOS PRIVADOS
     */

    @HostListener('window:resize', ['$event'])
    private onResize(event) {
        // Tamaño cabecera + border inferior + título
        const headersHeight = 75 + 1 + 40.2;
        const windowHeight = (window.innerHeight - headersHeight);
        let mapHeightNumber = windowHeight;
        if (this.isComputerLayout()) {
            const filtersHeight = this.filtros.nativeElement.clientHeight;
            mapHeightNumber = windowHeight > filtersHeight ? windowHeight : filtersHeight;
        }
        const mapHeight = mapHeightNumber + 'px';

        this.renderer.setStyle(this.mapDom.nativeElement, 'height', mapHeight);
    }

    private isComputerLayout() {
        return window.innerWidth > 992; //992 son los pixeles de bootstrap lg
    }

    private getFillColor(filterClaveSet: Set<any>, claveMarker: string): string {
        let clave = '0';
        if (filterClaveSet.has(claveMarker)) {
            clave = claveMarker;
        }
        switch (clave) {
            case '1':
                return BLACK;
            case '2':
                return ORANGE;
            case '3':
                return RED;
            case '4':
                return BLUE;
            default:
                return BLACK;
        }
    }

    private escapeUndefined(e) {
        return e ? e : '';
    }

    private processReconocimiento(current) {
        let result = current.nombre ?? '';
        if (current.puesto) {
            result += ' ' + current.puesto;
        }
        if (current.categoria) {
            result += ' en la categoria de ' + current.categoria;
        }
        return result !== '' ? '- ' + result + '. <br>' : '';
    }

    private processReferencias(current) {
        const result = current ?? '';
        return result !== '' ? '- ' + result + '.<br>' : '';
    }

    private getContenidoModal(feature): ContenidoModal {
        const fotografias = feature.properties.fotografias ? feature.properties.fotografias.map(e => e as FotografiaDTO).sort(this.fotografiasComparator) : null;
        const fotografa = feature.properties.fotografias ? feature.properties.fotografias[0].fotografa.nombre + ' ' + feature.properties.fotografias[0].fotografa.apellidos : '';
        const arquitectas = feature.properties.autoras.reduce((accumulator, currentvalue, i) => accumulator + (i > 0 ? ', ' : '' ) + currentvalue.nombre.concat(' ' + currentvalue.apellidos), '');
        const referencias = feature.properties.publicacion ? feature.properties.publicacion.reduce((accumulator, currentvalue) => accumulator +
            this.processReferencias(currentvalue.referenciaPublicacion), '') : '';
        const reconocimientos = feature.properties.reconocimientos ? feature.properties.reconocimientos.reduce((accumulator, currentvalue) => accumulator +
            this.processReconocimiento(currentvalue), '') : '';
        const fechaInicio = this.escapeUndefined(feature.properties.fechaInicio);
        const fechaFin = this.escapeUndefined(feature.properties.fechaFin);
        const equipoDeTrabajo = this.escapeUndefined(feature.properties.modo.nombre);
        const colaboradores = this.escapeUndefined(feature.properties.modo.colaboradores);
        const textoCritico = feature.properties.textoCritico;
        const estatus = this.estaus[feature.properties.estatus];
        const tipoProduccion1 = this.tiposProduccion1Dict[feature.properties.tipoProduccion1];
        const direccion = feature.properties.direccion;
        const comunidadAutonoma = this.comunidadesAutonomasDict[feature.properties.comunidadAutonoma];
        const periodoHistorico = feature.properties.periodoHistorico.nombre + ' ' + feature.properties.periodoHistorico.años;
        const nombre = feature.properties.nombre;
        return {
            fotografias,
            fotografa,
            textoCritico,
            reconocimientos,
            referencias,
            estatus,
            tipoProduccion1,
            colaboradores,
            direccion,
            comunidadAutonoma,
            equipoDeTrabajo,
            fechaFin,
            fechaInicio,
            periodoHistorico,
            nombre,
            arquitectas
        };
    }

    private fotografiasComparator(value1, value2) {
        return value1.fotografia.name.localeCompare(value2.fotografia.name);
    }

    private alphabeticComparator(value1, value2) {
        //Poner OTROS al final
        if (value1.key === 'OTROS') {
            return true;
        }
        if (value2.key === 'OTROS') {
            return false;
        }
        return value1.name.localeCompare(value2.name);
    }

    private dictionaryToArray(dict: Dictionary<any>): { key: string, name: string } [] {
        const result = [];
        for (const key in dict) {
            if (dict.hasOwnProperty(key)) {
                const name = dict[key];
                result.push({key, name});
            }
        }
        result.sort(this.alphabeticComparator);
        return result;
    }

    private getClavesPeriodoHistorico(periodoHistoricoList: PeriodoHistoricoDTO[]): Set<any> {
        const result = new Set();
        periodoHistoricoList.map(e => result.add(e.clave));
        return result;
    }

    private updateContadoresWidth() {
        const maxNumber = Math.max(this.contadorObras, this.contadorArquitectas, this.contadorFotografas);
        const maxNumberLength = maxNumber.toString().length;
        this.contadoresWidth = maxNumberLength + 'rem';
    }
}
