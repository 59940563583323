import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContenidoModal} from "app/public/home/home.component";
import {FotografiaDTO} from "app/apina/apina-api";
import {FotografiaUtilService} from "app/shared/util/fotografia-util.service";

@Component({
    selector: 'modal-obra-component',
    templateUrl: 'modal-obra.component.html',
    styleUrls: ['modal-obra.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalObraComponent {
    contenidoModal: ContenidoModal = null;

    constructor(public activeModal: NgbActiveModal,
                private fotografiaUtils: FotografiaUtilService) {
    }

    getFotografia(f: FotografiaDTO): string {
        return this.fotografiaUtils.getFotografia(f);
    }

    openPhoto(f: FotografiaDTO): void {
        window.open(this.getFotografia(f), '_blank');
    }

}
