import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ProfileInfo} from './profile-info.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProfileService {
    private infoUrl = 'management/info';
    private profileInfo: Promise<ProfileInfo>;

    constructor(private http: HttpClient) {
    }

    getProfileInfo(): Promise<ProfileInfo> {
        if (!this.profileInfo) {
            this.profileInfo = this.http
                .get<ProfileInfo>(this.infoUrl, {observe: 'response'})
                .pipe(
                    map((res: HttpResponse<ProfileInfo>) => {
                        const data = res.body;
                        const pi = new ProfileInfo();
                        pi.activeProfiles = data['activeProfiles'];
                        const displayRibbonOnEnv = data['display-ribbon-on-environments'].split(',');
                        const environment = data['environment'];
                        if (displayRibbonOnEnv && displayRibbonOnEnv.length > 0) {
                            const ribbonEnv = displayRibbonOnEnv.filter(env => env.toLowerCase() === environment.toLowerCase());
                            if (ribbonEnv.length > 0) {
                                pi.ribbonEnv = ribbonEnv[0].toUpperCase();
                            }
                        }
                        if (pi.activeProfiles) {
                            pi.inProduction = pi.activeProfiles.includes('prod');
                            pi.swaggerEnabled = pi.activeProfiles.includes('swagger');
                        }
                        return pi;
                    }))
                .toPromise();
        }
        return this.profileInfo;
    }
}
