/* tslint:disable */
import { Page, Pageable, Slice, Sort } from 'fw-core';

import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Dictionary<V> {
    [key: string]: V;
}

export type Instant = {};

export enum ComunidadAutonoma { ANDALUCIA = "ANDALUCIA", ARAGON = "ARAGON", PRINCIPADO_DE_ASTURIAS = "PRINCIPADO_DE_ASTURIAS", BALEARES = "BALEARES", CANARIAS = "CANARIAS", CANTABRIA = "CANTABRIA", CASTILLA_Y_LEON = "CASTILLA_Y_LEON", CASTILLA_LA_MANCHA = "CASTILLA_LA_MANCHA", CATALUNA = "CATALUNA", COMUNIDAD_VALENCIANA = "COMUNIDAD_VALENCIANA", EXTREMADURA = "EXTREMADURA", GALICIA = "GALICIA", COMUNIDAD_DE_MADRID = "COMUNIDAD_DE_MADRID", REGION_DE_MURCIA = "REGION_DE_MURCIA", COMUNIDAD_FORAL_DE_NAVARRA = "COMUNIDAD_FORAL_DE_NAVARRA", PAIS_VASCO = "PAIS_VASCO", LA_RIOJA = "LA_RIOJA", CEUTA = "CEUTA", MELILLA = "MELILLA" }
export enum Estatus { CONSTRUIDO = "CONSTRUIDO", NO_CONSTRUIDO = "NO_CONSTRUIDO", DEMOLIDO = "DEMOLIDO" }
export enum TipoPersona { AUTOR = "AUTOR", INVESTIGADOR = "INVESTIGADOR", FOTOGRAFO = "FOTOGRAFO" }
export enum TipoProduccion1 { PAISAJISMO = "PAISAJISMO", PLANEAMIENTO_URBANO = "PLANEAMIENTO_URBANO", DISENO_URBANO = "DISENO_URBANO", SERVICIOS_PUBLICOS = "SERVICIOS_PUBLICOS", USO_ADMINISTRATIVO = "USO_ADMINISTRATIVO", USO_COMERCIAL = "USO_COMERCIAL", USO_CULTURAL_OCIO_TURISMO = "USO_CULTURAL_OCIO_TURISMO", USO_DEPORTIVO = "USO_DEPORTIVO", USO_DOCENTE = "USO_DOCENTE", USO_INDUSTRIAL_AGRICOLA = "USO_INDUSTRIAL_AGRICOLA", USO_MILITAR = "USO_MILITAR", USO_RELIGIOSO = "USO_RELIGIOSO", USO_SANITARIO_BIENESTAR_SOCIAL = "USO_SANITARIO_BIENESTAR_SOCIAL", TRANSPORTE_SERVICIO_INFRAESTUCTURAS = "TRANSPORTE_SERVICIO_INFRAESTUCTURAS", VIVIENDA_UNIFAMILIAR = "VIVIENDA_UNIFAMILIAR", VIVIENDA_COLECTIVA_PRIVADA = "VIVIENDA_COLECTIVA_PRIVADA", VIVIENDA_COLECTIVA_SOCIAL = "VIVIENDA_COLECTIVA_SOCIAL", CONJUNTO_DE_VIVIENDAS = "CONJUNTO_DE_VIVIENDAS", POBLADO = "POBLADO", INSTALACION_DE_CARACTER_TEMPORAL_EXPOSICION = "INSTALACION_DE_CARACTER_TEMPORAL_EXPOSICION", DISENO_MOBILIARIO = "DISENO_MOBILIARIO", CONMEMORATIVO_ARQUITECTURAS_UTOPICAS = "CONMEMORATIVO_ARQUITECTURAS_UTOPICAS", OTROS = "OTROS" }
export enum TipoProduccion2 { INTERVENCION_EN_PATRIMONIO = "INTERVENCION_EN_PATRIMONIO", INTERIORISMO = "INTERIORISMO", OBRA_NUEVA = "OBRA_NUEVA" }

export interface AccountDTO {
    authorities?: string[];
    avatar?: FwFileDTO;
    avatarUrl?: string;
    email?: string;
    firstName?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    loginUserOriginal?: string;
    validated?: boolean;
    version?: number;
}

export interface AuditDTO {
    createdBy?: string;
    createdDate?: Instant;
    lastModifiedBy?: string;
    lastModifiedDate?: Instant;
}

export interface Concurso {
    descripcion?: string;
    id?: number;
    label?: string;
    nombre?: string;
}

export interface ConcursoDTO {
    descripcion?: string;
    id?: number;
    nombre?: string;
}

export interface ContadoresDTO {
    arquitectas?: number;
    fotografas?: number;
    obras?: number;
}

export interface Coordinate {
    m?: number;
    valid?: boolean;
    x?: number;
    y?: number;
    z?: number;
}

export interface CoordinateSequence {
    dimension?: number;
    measures?: number;
}

export interface CoordinateSequenceFactory {
}

export interface Envelope {
    area?: number;
    diameter?: number;
    height?: number;
    maxX?: number;
    maxY?: number;
    minX?: number;
    minY?: number;
    null?: boolean;
    width?: number;
}

export interface Fotografia {
    fotografa?: Persona;
    fotografia?: FwFile;
    id?: number;
    label?: string;
    obra?: Obra;
    orden?: number;
}

export interface FotografiaDTO {
    fotografa?: PersonaDTO;
    fotografia?: FwFileDTO;
    id?: number;
    obra?: ObraListDTO;
    orden?: number;
}

export interface FotografiaFilter {
    clavesPeriodoHistorico?: string[];
    comunidadAutonoma?: ComunidadAutonoma;
    fechaInicioInferior?: string;
    fechaInicioSuperior?: string;
    idArquitecta?: number;
    idFotografa?: number;
    modo?: ModoDTO;
    searchTerm?: string;
    tiposProduccion1?: TipoProduccion1[];
    tiposProduccion2?: TipoProduccion2[];
}

export interface FwFile {
    id?: number;
    name?: string;
    path?: string;
    relativePath?: string;
    relativePathWithoutName?: string;
}

export interface FwFileDTO {
    id?: number;
    name?: string;
    temporalFileName?: string;
}

export interface Geometry {
    area?: number;
    boundary?: Geometry;
    boundaryDimension?: number;
    centroid?: Point;
    coordinate?: Coordinate;
    coordinates?: Coordinate[];
    dimension?: number;
    empty?: boolean;
    envelope?: Geometry;
    envelopeInternal?: Envelope;
    factory?: GeometryFactory;
    geometryType?: string;
    interiorPoint?: Point;
    length?: number;
    numGeometries?: number;
    numPoints?: number;
    precisionModel?: PrecisionModel;
    rectangle?: boolean;
    sRID?: number;
    simple?: boolean;
    userData?: any;
    valid?: boolean;
}

export interface GeometryFactory {
    coordinateSequenceFactory?: CoordinateSequenceFactory;
    precisionModel?: PrecisionModel;
    sRID?: number;
}

export interface KeyAndPasswordDTO {
    key?: string;
    newPassword?: string;
}

export interface LanguageDTO {
    code?: string;
    default?: boolean;
    name?: string;
    translation?: string;
}

export interface ModalFotografiaDTO {
    fotografa?: PersonaDTO;
    fotografia?: FwFileDTO;
    id?: number;
    orden?: number;
}

export interface ModalObraDTO {
    autoras?: PersonaDTO[];
    colaboradores?: string;
    comunidadAutonoma?: ComunidadAutonoma;
    concursos?: ConcursoDTO[];
    direccion?: string;
    estatus?: Estatus;
    fechaFin?: string;
    fechaInicio?: string;
    fotografias?: ModalFotografiaDTO[];
    modo?: ModoDTO;
    nombre?: string;
    periodoHistorico?: PeriodoHistoricoDTO;
    publicaciones?: PublicacionDTO[];
    textoCritico?: string;
    tipoProduccion1?: TipoProduccion1;
}

export interface Modo {
    clave?: string;
    id?: number;
    label?: string;
    nombre?: string;
    obras?: Obra[];
}

export interface ModoDTO {
    id?: number;
    label?: string;
    nombre?: string;
}

export interface ModoFilter {
    searchTerm?: string;
}

export interface Obra {
    autoras?: Persona[];
    codigoImagen?: string;
    colaboradores?: string;
    comunidadAutonoma?: ComunidadAutonoma;
    concursos?: Concurso[];
    coordenadas?: Geometry;
    direccion?: string;
    estatus?: Estatus;
    fechaFin?: string;
    fechaInicio?: string;
    fotografa?: string;
    fotografias?: Fotografia[];
    id?: number;
    investigadores?: Persona[];
    label?: string;
    modo?: Modo;
    nombre?: string;
    obraConcursos?: ObraConcurso[];
    periodoHistorico?: PeriodoHistorico;
    publicaciones?: Publicacion[];
    referenciaWeb?: string;
    textoCritico?: string;
    tipoProduccion1?: TipoProduccion1;
    tipoProduccion2?: TipoProduccion2;
}

export interface ObraConcurso {
    categoria?: string;
    concurso?: Concurso;
    id?: number;
    label?: string;
    obra?: Obra;
    puesto?: string;
}

export interface ObraConcursoDTO {
    categoria?: string;
    concurso?: ConcursoDTO;
    id?: number;
    puesto?: string;
}

export interface ObraFilter {
    autora?: string;
    comunidadAutonoma?: ComunidadAutonoma;
    fechaInicio?: string;
    modo?: Modo;
    periodoHistorico?: PeriodoHistorico;
    revista?: string;
    searchTerm?: string;
}

export interface ObraFormDTO {
    autoras?: PersonaDTO[];
    comunidadAutonoma?: ComunidadAutonoma;
    direccion?: string;
    estatus?: Estatus;
    fechaFin?: string;
    fechaInicio?: string;
    fotografaDTO?: PersonaDTO;
    fotografias?: FotografiaDTO[];
    id?: number;
    latitud?: number;
    longitud?: number;
    modo?: ModoDTO;
    nombre?: string;
    obraConcursos?: ObraConcursoDTO[];
    periodoHistorico?: PeriodoHistoricoDTO;
    publicaciones?: PublicacionDTO[];
    referenciaWeb?: string;
    textoCritico?: string;
    tipoProduccion1?: TipoProduccion1;
    tipoProduccion2?: TipoProduccion2;
}

export interface ObraListDTO {
    autoras?: PersonaDTO[];
    fechaInicio?: string;
    id?: number;
    nombre?: string;
    tipoProduccion1?: TipoProduccion1;
    tipoProduccion2?: TipoProduccion2;
}

export interface PasswordChangeDTO {
    currentPassword?: string;
    newPassword?: string;
}

export interface PeriodoHistorico {
    anos?: string;
    clave?: string;
    fin?: string;
    id?: number;
    inicio?: string;
    label?: string;
    nombre?: string;
    obra?: Obra[];
}

export interface PeriodoHistoricoDTO {
    clave?: string;
    fin?: string;
    id?: number;
    inicio?: string;
    label?: string;
    nombre?: string;
}

export interface PeriodoHistoricoFilter {
    searchTerm?: string;
}

export interface Persona {
    apellidos?: string;
    id?: number;
    label?: string;
    nombre?: string;
    tipo?: TipoPersona;
}

export interface PersonaDTO {
    apellidos?: string;
    id?: number;
    label?: string;
    nombre?: string;
    tipo?: TipoPersona;
}

export interface PersonaFilter {
    searchTerm?: string;
}

export interface Point {
    area?: number;
    boundary?: Geometry;
    boundaryDimension?: number;
    centroid?: Point;
    coordinate?: Coordinate;
    coordinateSequence?: CoordinateSequence;
    coordinates?: Coordinate[];
    dimension?: number;
    empty?: boolean;
    envelope?: Geometry;
    envelopeInternal?: Envelope;
    factory?: GeometryFactory;
    geometryType?: string;
    interiorPoint?: Point;
    length?: number;
    numGeometries?: number;
    numPoints?: number;
    precisionModel?: PrecisionModel;
    rectangle?: boolean;
    sRID?: number;
    simple?: boolean;
    userData?: any;
    valid?: boolean;
    x?: number;
    y?: number;
}

export interface PrecisionModel {
    floating?: boolean;
    maximumSignificantDigits?: number;
    offsetX?: number;
    offsetY?: number;
    scale?: number;
    type?: Type;
}

export interface Publicacion {
    id?: number;
    label?: string;
    obra?: Obra;
    referencia?: string;
    revista?: Revista;
}

export interface PublicacionDTO {
    id?: number;
    referencia?: string;
}

export interface Revista {
    colegioDeArquitectos?: string;
    id?: number;
    label?: string;
    lugarDePublicacion?: string;
    nombre?: string;
    perteneceColegioArquitectos?: boolean;
    publicacionList?: Publicacion[];
}

export interface Type {
}

export interface UserBasicDTO {
    id?: number;
    label?: string;
    login?: string;
    version?: number;
}

export interface UserFilter {
    creationInstantFrom?: Instant;
    creationInstantTo?: Instant;
    login?: string;
    role?: string;
    searchTerm?: string;
}

export interface UserFormDTO {
    audit?: AuditDTO;
    authorities?: string[];
    avatar?: FwFileDTO;
    blocked?: boolean;
    email?: string;
    firstName?: string;
    id?: number;
    imageUrl?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    resetKey?: string;
    resetLink?: string;
    validated?: boolean;
    validationKey?: string;
    validationLink?: string;
    version?: number;
}

export interface UserFormNewDTO {
    authorities?: string[];
    email?: string;
    firstName?: string;
    imageUrl?: string;
    lastName?: string;
    login?: string;
    password?: string;
    validated?: boolean;
}

export interface UserListDTO {
    audit?: AuditDTO;
    authorities?: string[];
    blocked?: boolean;
    email?: string;
    id?: number;
    imageUrl?: string;
    lastLogin?: Instant;
    login?: string;
    validated?: boolean;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('Instant');

    config.registerIdentitySerializer('ComunidadAutonoma');
    config.registerIdentitySerializer('Estatus');
    config.registerIdentitySerializer('TipoPersona');
    config.registerIdentitySerializer('TipoProduccion1');
    config.registerIdentitySerializer('TipoProduccion2');

    config.registerClassSerializer('AccountDTO', {
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'avatarUrl': 'string',
        'email': 'string',
        'firstName': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'loginUserOriginal': 'string',
        'validated': 'boolean',
        'version': 'number'
    });

    config.registerClassSerializer('AuditDTO', {
        'createdBy': 'string',
        'createdDate': 'Instant',
        'lastModifiedBy': 'string',
        'lastModifiedDate': 'Instant'
    });

    config.registerClassSerializer('Concurso', {
        'descripcion': 'string',
        'id': 'number',
        'label': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('ConcursoDTO', {
        'descripcion': 'string',
        'id': 'number',
        'nombre': 'string'
    });

    config.registerClassSerializer('ContadoresDTO', {
        'arquitectas': 'number',
        'fotografas': 'number',
        'obras': 'number'
    });

    config.registerClassSerializer('Coordinate', {
        'm': 'number',
        'valid': 'boolean',
        'x': 'number',
        'y': 'number',
        'z': 'number'
    });

    config.registerClassSerializer('CoordinateSequence', {
        'dimension': 'number',
        'measures': 'number'
    });

    config.registerClassSerializer('CoordinateSequenceFactory', {});

    config.registerClassSerializer('Envelope', {
        'area': 'number',
        'diameter': 'number',
        'height': 'number',
        'maxX': 'number',
        'maxY': 'number',
        'minX': 'number',
        'minY': 'number',
        'null': 'boolean',
        'width': 'number'
    });

    config.registerClassSerializer('Fotografia', {
        'fotografa': 'Persona',
        'fotografia': 'FwFile',
        'id': 'number',
        'label': 'string',
        'obra': 'Obra',
        'orden': 'number'
    });

    config.registerClassSerializer('FotografiaDTO', {
        'fotografa': 'PersonaDTO',
        'fotografia': 'FwFileDTO',
        'id': 'number',
        'obra': 'ObraListDTO',
        'orden': 'number'
    });

    config.registerClassSerializer('FotografiaFilter', {
        'clavesPeriodoHistorico': 'string[]',
        'comunidadAutonoma': 'ComunidadAutonoma',
        'fechaInicioInferior': 'string',
        'fechaInicioSuperior': 'string',
        'idArquitecta': 'number',
        'idFotografa': 'number',
        'modo': 'ModoDTO',
        'searchTerm': 'string',
        'tiposProduccion1': 'TipoProduccion1[]',
        'tiposProduccion2': 'TipoProduccion2[]'
    });

    config.registerClassSerializer('FwFile', {
        'id': 'number',
        'name': 'string',
        'path': 'string',
        'relativePath': 'string',
        'relativePathWithoutName': 'string'
    });

    config.registerClassSerializer('FwFileDTO', {
        'id': 'number',
        'name': 'string',
        'temporalFileName': 'string'
    });

    config.registerClassSerializer('Geometry', {
        'area': 'number',
        'boundary': 'Geometry',
        'boundaryDimension': 'number',
        'centroid': 'Point',
        'coordinate': 'Coordinate',
        'coordinates': 'Coordinate[]',
        'dimension': 'number',
        'empty': 'boolean',
        'envelope': 'Geometry',
        'envelopeInternal': 'Envelope',
        'factory': 'GeometryFactory',
        'geometryType': 'string',
        'interiorPoint': 'Point',
        'length': 'number',
        'numGeometries': 'number',
        'numPoints': 'number',
        'precisionModel': 'PrecisionModel',
        'rectangle': 'boolean',
        'sRID': 'number',
        'simple': 'boolean',
        'userData': 'any',
        'valid': 'boolean'
    });

    config.registerClassSerializer('GeometryFactory', {
        'coordinateSequenceFactory': 'CoordinateSequenceFactory',
        'precisionModel': 'PrecisionModel',
        'sRID': 'number'
    });

    config.registerClassSerializer('KeyAndPasswordDTO', {
        'key': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('LanguageDTO', {
        'code': 'string',
        'default': 'boolean',
        'name': 'string',
        'translation': 'string'
    });

    config.registerClassSerializer('ModalFotografiaDTO', {
        'fotografa': 'PersonaDTO',
        'fotografia': 'FwFileDTO',
        'id': 'number',
        'orden': 'number'
    });

    config.registerClassSerializer('ModalObraDTO', {
        'autoras': 'PersonaDTO[]',
        'colaboradores': 'string',
        'comunidadAutonoma': 'ComunidadAutonoma',
        'concursos': 'ConcursoDTO[]',
        'direccion': 'string',
        'estatus': 'Estatus',
        'fechaFin': 'string',
        'fechaInicio': 'string',
        'fotografias': 'ModalFotografiaDTO[]',
        'modo': 'ModoDTO',
        'nombre': 'string',
        'periodoHistorico': 'PeriodoHistoricoDTO',
        'publicaciones': 'PublicacionDTO[]',
        'textoCritico': 'string',
        'tipoProduccion1': 'TipoProduccion1'
    });

    config.registerClassSerializer('Modo', {
        'clave': 'string',
        'id': 'number',
        'label': 'string',
        'nombre': 'string',
        'obras': 'Obra[]'
    });

    config.registerClassSerializer('ModoDTO', {
        'id': 'number',
        'label': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('ModoFilter', {
        'searchTerm': 'string'
    });

    config.registerClassSerializer('Obra', {
        'autoras': 'Persona[]',
        'codigoImagen': 'string',
        'colaboradores': 'string',
        'comunidadAutonoma': 'ComunidadAutonoma',
        'concursos': 'Concurso[]',
        'coordenadas': 'Geometry',
        'direccion': 'string',
        'estatus': 'Estatus',
        'fechaFin': 'string',
        'fechaInicio': 'string',
        'fotografa': 'string',
        'fotografias': 'Fotografia[]',
        'id': 'number',
        'investigadores': 'Persona[]',
        'label': 'string',
        'modo': 'Modo',
        'nombre': 'string',
        'obraConcursos': 'ObraConcurso[]',
        'periodoHistorico': 'PeriodoHistorico',
        'publicaciones': 'Publicacion[]',
        'referenciaWeb': 'string',
        'textoCritico': 'string',
        'tipoProduccion1': 'TipoProduccion1',
        'tipoProduccion2': 'TipoProduccion2'
    });

    config.registerClassSerializer('ObraConcurso', {
        'categoria': 'string',
        'concurso': 'Concurso',
        'id': 'number',
        'label': 'string',
        'obra': 'Obra',
        'puesto': 'string'
    });

    config.registerClassSerializer('ObraConcursoDTO', {
        'categoria': 'string',
        'concurso': 'ConcursoDTO',
        'id': 'number',
        'puesto': 'string'
    });

    config.registerClassSerializer('ObraFilter', {
        'autora': 'string',
        'comunidadAutonoma': 'ComunidadAutonoma',
        'fechaInicio': 'string',
        'modo': 'Modo',
        'periodoHistorico': 'PeriodoHistorico',
        'revista': 'string',
        'searchTerm': 'string'
    });

    config.registerClassSerializer('ObraFormDTO', {
        'autoras': 'PersonaDTO[]',
        'comunidadAutonoma': 'ComunidadAutonoma',
        'direccion': 'string',
        'estatus': 'Estatus',
        'fechaFin': 'string',
        'fechaInicio': 'string',
        'fotografaDTO': 'PersonaDTO',
        'fotografias': 'FotografiaDTO[]',
        'id': 'number',
        'latitud': 'number',
        'longitud': 'number',
        'modo': 'ModoDTO',
        'nombre': 'string',
        'obraConcursos': 'ObraConcursoDTO[]',
        'periodoHistorico': 'PeriodoHistoricoDTO',
        'publicaciones': 'PublicacionDTO[]',
        'referenciaWeb': 'string',
        'textoCritico': 'string',
        'tipoProduccion1': 'TipoProduccion1',
        'tipoProduccion2': 'TipoProduccion2'
    });

    config.registerClassSerializer('ObraListDTO', {
        'autoras': 'PersonaDTO[]',
        'fechaInicio': 'string',
        'id': 'number',
        'nombre': 'string',
        'tipoProduccion1': 'TipoProduccion1',
        'tipoProduccion2': 'TipoProduccion2'
    });

    config.registerClassSerializer('PasswordChangeDTO', {
        'currentPassword': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('PeriodoHistorico', {
        'anos': 'string',
        'clave': 'string',
        'fin': 'string',
        'id': 'number',
        'inicio': 'string',
        'label': 'string',
        'nombre': 'string',
        'obra': 'Obra[]'
    });

    config.registerClassSerializer('PeriodoHistoricoDTO', {
        'clave': 'string',
        'fin': 'string',
        'id': 'number',
        'inicio': 'string',
        'label': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('PeriodoHistoricoFilter', {
        'searchTerm': 'string'
    });

    config.registerClassSerializer('Persona', {
        'apellidos': 'string',
        'id': 'number',
        'label': 'string',
        'nombre': 'string',
        'tipo': 'TipoPersona'
    });

    config.registerClassSerializer('PersonaDTO', {
        'apellidos': 'string',
        'id': 'number',
        'label': 'string',
        'nombre': 'string',
        'tipo': 'TipoPersona'
    });

    config.registerClassSerializer('PersonaFilter', {
        'searchTerm': 'string'
    });

    config.registerClassSerializer('Point', {
        'area': 'number',
        'boundary': 'Geometry',
        'boundaryDimension': 'number',
        'centroid': 'Point',
        'coordinate': 'Coordinate',
        'coordinateSequence': 'CoordinateSequence',
        'coordinates': 'Coordinate[]',
        'dimension': 'number',
        'empty': 'boolean',
        'envelope': 'Geometry',
        'envelopeInternal': 'Envelope',
        'factory': 'GeometryFactory',
        'geometryType': 'string',
        'interiorPoint': 'Point',
        'length': 'number',
        'numGeometries': 'number',
        'numPoints': 'number',
        'precisionModel': 'PrecisionModel',
        'rectangle': 'boolean',
        'sRID': 'number',
        'simple': 'boolean',
        'userData': 'any',
        'valid': 'boolean',
        'x': 'number',
        'y': 'number'
    });

    config.registerClassSerializer('PrecisionModel', {
        'floating': 'boolean',
        'maximumSignificantDigits': 'number',
        'offsetX': 'number',
        'offsetY': 'number',
        'scale': 'number',
        'type': 'Type'
    });

    config.registerClassSerializer('Publicacion', {
        'id': 'number',
        'label': 'string',
        'obra': 'Obra',
        'referencia': 'string',
        'revista': 'Revista'
    });

    config.registerClassSerializer('PublicacionDTO', {
        'id': 'number',
        'referencia': 'string'
    });

    config.registerClassSerializer('Revista', {
        'colegioDeArquitectos': 'string',
        'id': 'number',
        'label': 'string',
        'lugarDePublicacion': 'string',
        'nombre': 'string',
        'perteneceColegioArquitectos': 'boolean',
        'publicacionList': 'Publicacion[]'
    });

    config.registerClassSerializer('Type', {});

    config.registerClassSerializer('UserBasicDTO', {
        'id': 'number',
        'label': 'string',
        'login': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFilter', {
        'creationInstantFrom': 'Instant',
        'creationInstantTo': 'Instant',
        'login': 'string',
        'role': 'string',
        'searchTerm': 'string'
    });

    config.registerClassSerializer('UserFormDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'blocked': 'boolean',
        'email': 'string',
        'firstName': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'resetKey': 'string',
        'resetLink': 'string',
        'validated': 'boolean',
        'validationKey': 'string',
        'validationLink': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFormNewDTO', {
        'authorities': 'string[]',
        'email': 'string',
        'firstName': 'string',
        'imageUrl': 'string',
        'lastName': 'string',
        'login': 'string',
        'password': 'string',
        'validated': 'boolean'
    });

    config.registerClassSerializer('UserListDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'blocked': 'boolean',
        'email': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'lastLogin': 'Instant',
        'login': 'string',
        'validated': 'boolean'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }
        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    return {
        serialize(value) {
            return safeMap(value, elementSerializer.serialize.bind(elementSerializer));
        },
        deserialize(value) {
            return safeMap(value, elementSerializer.deserialize.bind(elementSerializer));
        }
    }
}

export interface RequestData {
    uriTemplate: string;
    method: string;
    pathVariables?: any;
    requestParams?: any;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }


        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

@Injectable( {providedIn: 'root'} )
export class AccountResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    changePassword(passwordChangeDto: PasswordChangeDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/change-password',
            'method': 'POST',
            'requestBody': this.context.serialize(passwordChangeDto, 'PasswordChangeDTO')
        });
    }

    changePasswordUrl(passwordChangeDto: PasswordChangeDTO): string {
        return this.context.buildUrl('/account/change-password' , null);
    }

    finishPasswordReset(keyAndPassword: KeyAndPasswordDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/reset-password/finish',
            'method': 'POST',
            'requestBody': this.context.serialize(keyAndPassword, 'KeyAndPasswordDTO')
        });
    }

    finishPasswordResetUrl(keyAndPassword: KeyAndPasswordDTO): string {
        return this.context.buildUrl('/account/reset-password/finish' , null);
    }

    getAccount(): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/account',
            'method': 'GET',
            'responseType': 'AccountDTO'
        });
    }

    getAccountUrl(): string {
        return this.context.buildUrl('/account' , null);
    }

    isAuthenticated(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/authenticate',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    isAuthenticatedUrl(): string {
        return this.context.buildUrl('/authenticate' , null);
    }

    registerAccount(userFormNewDTO: UserFormNewDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/register',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormNewDTO, 'UserFormNewDTO')
        });
    }

    registerAccountUrl(userFormNewDTO: UserFormNewDTO): string {
        return this.context.buildUrl('/register' , null);
    }

    requestPasswordReset(mail: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/reset-password/init',
            'method': 'POST',
            'requestBody': this.context.serialize(mail, 'string')
        });
    }

    requestPasswordResetUrl(mail: string): string {
        return this.context.buildUrl('/account/reset-password/init' , null);
    }

    saveAccount(accountDTO: AccountDTO): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/account',
            'method': 'POST',
            'requestBody': this.context.serialize(accountDTO, 'AccountDTO'),
            'responseType': 'AccountDTO'
        });
    }

    saveAccountUrl(accountDTO: AccountDTO): string {
        return this.context.buildUrl('/account' , null);
    }

    validateAccount(key: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/validate',
            'method': 'GET',
            'requestParams': {
                'key': this.context.serialize(key, 'string')
            }
        });
    }

    validateAccountUrl(key: string): string {
        return this.context.buildUrl('/validate' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class EnumResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

}

@Injectable( {providedIn: 'root'} )
export class FicheroResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    downloadTemporalFile(temporalFileName: string, fileName: string, fileCodification: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/files/{temporalFileName}',
            'method': 'GET',
            'pathVariables': {
                'temporalFileName': this.context.serialize(temporalFileName, 'string')
            },
            'requestParams': {
                'fileName': this.context.serialize(fileName, 'string'),
                'fileCodification': this.context.serialize(fileCodification, 'string')
            }
        });
    }

    downloadTemporalFileUrl(temporalFileName: string, fileName: string, fileCodification: string): string {
        return this.context.buildUrl('/files/{temporalFileName}' , {
            'temporalFileName': this.context.serialize(temporalFileName, 'string')
        });
    }

    saveTemporalFile(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/files/temporal',
            'method': 'POST',
            'responseType': 'string'
        });
    }

    saveTemporalFileUrl(): string {
        return this.context.buildUrl('/files/temporal' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class FotografiaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    descargarImagen(ficheroId: number, nombreImagen: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/fotografia/descargar/{ficheroId}/{nombreImagen}',
            'method': 'GET',
            'pathVariables': {
                'ficheroId': this.context.serialize(ficheroId, 'number'),
                'nombreImagen': this.context.serialize(nombreImagen, 'string')
            }
        });
    }

    descargarImagenUrl(ficheroId: number, nombreImagen: string): string {
        return this.context.buildUrl('/fotografia/descargar/{ficheroId}/{nombreImagen}' , {
            'ficheroId': this.context.serialize(ficheroId, 'number'),
            'nombreImagen': this.context.serialize(nombreImagen, 'string')
        });
    }

    findAll(pageable: Pageable, filter: FotografiaFilter): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/fotografia',
            'method': 'GET',
            'requestParams': {
                'pageable': this.context.serialize(pageable, 'Pageable'),
                'filter': this.context.serialize(filter, 'FotografiaFilter')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(pageable: Pageable, filter: FotografiaFilter): string {
        return this.context.buildUrl('/fotografia' , null);
    }

    get(id: number): Observable<FotografiaDTO> {
        return this.context.request({
            'uriTemplate': '/fotografia/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'FotografiaDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/fotografia/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getContadores(filter: FotografiaFilter): Observable<ContadoresDTO> {
        return this.context.request({
            'uriTemplate': '/fotografia/contadores',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'FotografiaFilter')
            },
            'responseType': 'ContadoresDTO'
        });
    }

    getContadoresUrl(filter: FotografiaFilter): string {
        return this.context.buildUrl('/fotografia/contadores' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class LanguageResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getCurrentLanguage(): Observable<LanguageDTO> {
        return this.context.request({
            'uriTemplate': '/languages/current',
            'method': 'GET',
            'responseType': 'LanguageDTO'
        });
    }

    getCurrentLanguageUrl(): string {
        return this.context.buildUrl('/languages/current' , null);
    }

    getLanguages(): Observable<LanguageDTO[]> {
        return this.context.request({
            'uriTemplate': '/languages',
            'method': 'GET',
            'responseType': 'LanguageDTO[]'
        });
    }

    getLanguagesUrl(): string {
        return this.context.buildUrl('/languages' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class ModoResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    findAll(filtro: ModoFilter): Observable<ModoDTO[]> {
        return this.context.request({
            'uriTemplate': '/modo',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'ModoFilter')
            },
            'responseType': 'ModoDTO[]'
        });
    }

    findAllUrl(filtro: ModoFilter): string {
        return this.context.buildUrl('/modo' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class ObraResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(obraFormDTO: ObraFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/obras',
            'method': 'POST',
            'requestBody': this.context.serialize(obraFormDTO, 'ObraFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(obraFormDTO: ObraFormDTO): string {
        return this.context.buildUrl('/obras' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/obras/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/obras/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: ObraFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/obras',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'ObraFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: ObraFilter, page: Pageable): string {
        return this.context.buildUrl('/obras' , null);
    }

    findGeoJSON(filtro: ObraFilter): Observable<string> {
        return this.context.request({
            'uriTemplate': '/obras/geojson',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'ObraFilter')
            },
            'responseType': 'string'
        });
    }

    findGeoJSONUrl(filtro: ObraFilter): string {
        return this.context.buildUrl('/obras/geojson' , null);
    }

    get(id: number): Observable<ObraFormDTO> {
        return this.context.request({
            'uriTemplate': '/obras/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ObraFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/obras/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    importarExcel(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/obras/importarExcel',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    importarExcelUrl(): string {
        return this.context.buildUrl('/obras/importarExcel' , null);
    }

    importarFotografias(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/obras/importarFotografias',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    importarFotografiasUrl(): string {
        return this.context.buildUrl('/obras/importarFotografias' , null);
    }

    obtenerComunidadesAutonomas(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/obras/comunidadesAutonomas',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    obtenerComunidadesAutonomasUrl(): string {
        return this.context.buildUrl('/obras/comunidadesAutonomas' , null);
    }

    obtenerEstatus(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/obras/estatus',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    obtenerEstatusUrl(): string {
        return this.context.buildUrl('/obras/estatus' , null);
    }

    obtenerModal(id: number): Observable<ModalObraDTO> {
        return this.context.request({
            'uriTemplate': '/obras/modal/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ModalObraDTO'
        });
    }

    obtenerModalUrl(id: number): string {
        return this.context.buildUrl('/obras/modal/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    obtenerTiposProduccion(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/obras/tiposProduccion',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    obtenerTiposProduccionUrl(): string {
        return this.context.buildUrl('/obras/tiposProduccion' , null);
    }

    obtenerTiposProduccion2(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/obras/tiposProduccion2',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    obtenerTiposProduccion2Url(): string {
        return this.context.buildUrl('/obras/tiposProduccion2' , null);
    }

    update(id: number, obraDTO: ObraFormDTO): Observable<ObraFormDTO> {
        return this.context.request({
            'uriTemplate': '/obras/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(obraDTO, 'ObraFormDTO'),
            'responseType': 'ObraFormDTO'
        });
    }

    updateUrl(id: number, obraDTO: ObraFormDTO): string {
        return this.context.buildUrl('/obras/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class PeriodoHistoricoResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    findAll(filtro: PeriodoHistoricoFilter): Observable<PeriodoHistoricoDTO[]> {
        return this.context.request({
            'uriTemplate': '/periodoHistorico',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'PeriodoHistoricoFilter')
            },
            'responseType': 'PeriodoHistoricoDTO[]'
        });
    }

    findAllUrl(filtro: PeriodoHistoricoFilter): string {
        return this.context.buildUrl('/periodoHistorico' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class PersonaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(personaDTO: PersonaDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/persona',
            'method': 'POST',
            'requestBody': this.context.serialize(personaDTO, 'PersonaDTO'),
            'responseType': 'number'
        });
    }

    createUrl(personaDTO: PersonaDTO): string {
        return this.context.buildUrl('/persona' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/persona/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/persona/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: PersonaFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/persona',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'PersonaFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: PersonaFilter, pageable: Pageable): string {
        return this.context.buildUrl('/persona' , null);
    }

    findAllArquitectas(filtro: PersonaFilter): Observable<PersonaDTO[]> {
        return this.context.request({
            'uriTemplate': '/persona/arquitectas',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'PersonaFilter')
            },
            'responseType': 'PersonaDTO[]'
        });
    }

    findAllArquitectasUrl(filtro: PersonaFilter): string {
        return this.context.buildUrl('/persona/arquitectas' , null);
    }

    findAllFotografas(filtro: PersonaFilter): Observable<PersonaDTO[]> {
        return this.context.request({
            'uriTemplate': '/persona/fotografas',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'PersonaFilter')
            },
            'responseType': 'PersonaDTO[]'
        });
    }

    findAllFotografasUrl(filtro: PersonaFilter): string {
        return this.context.buildUrl('/persona/fotografas' , null);
    }

    get(id: number): Observable<PersonaDTO> {
        return this.context.request({
            'uriTemplate': '/persona/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PersonaDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/persona/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getTipoPersona(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/persona/tipos',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getTipoPersonaUrl(): string {
        return this.context.buildUrl('/persona/tipos' , null);
    }

    update(id: number, personaDTO: PersonaDTO): Observable<PersonaDTO> {
        return this.context.request({
            'uriTemplate': '/persona/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(personaDTO, 'PersonaDTO'),
            'responseType': 'PersonaDTO'
        });
    }

    updateUrl(id: number, personaDTO: PersonaDTO): string {
        return this.context.buildUrl('/persona/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class UserResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(userFormDTO: UserFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: UserFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'UserFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: UserFilter, pageable: Pageable): string {
        return this.context.buildUrl('/users' , null);
    }

    findAllOrdenadosPorLogin(): Observable<UserBasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/users/allOrderByLogin',
            'method': 'GET',
            'responseType': 'UserBasicDTO[]'
        });
    }

    findAllOrdenadosPorLoginUrl(): string {
        return this.context.buildUrl('/users/allOrderByLogin' , null);
    }

    get(id: number): Observable<UserFormDTO> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'UserFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAuthorities(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/users/authorities',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getAuthoritiesUrl(): string {
        return this.context.buildUrl('/users/authorities' , null);
    }

    getAvatar(login: string, avatarId: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{login}/{avatarId}/avatar.jpg',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'avatarId': this.context.serialize(avatarId, 'string')
            }
        });
    }

    getAvatarUrl(login: string, avatarId: string): string {
        return this.context.buildUrl('/users/{login}/{avatarId}/avatar.jpg' , {
            'login': this.context.serialize(login, 'string'),
            'avatarId': this.context.serialize(avatarId, 'string')
        });
    }

    update(id: number, userFormDTO: UserFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO')
        });
    }

    updateUrl(id: number, userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}


export function apinaConfigFactory() {
    return new ApinaConfig();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        { provide: ApinaEndpointContext, useClass: DefaultApinaEndpointContext },
        { provide: ApinaConfig, useFactory: apinaConfigFactory }
    ]
})
export class ApinaModule {}
