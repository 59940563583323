import {Component, OnInit, ViewChild} from '@angular/core';
import {
    ComunidadAutonoma,
    ContadoresDTO,
    FotografiaDTO,
    FotografiaFilter,
    FotografiaResourceEndpoint,
    ModalObraDTO,
    ModoDTO,
    ModoFilter,
    ModoResourceEndpoint,
    ObraResourceEndpoint,
    PeriodoHistoricoDTO,
    PeriodoHistoricoFilter,
    PeriodoHistoricoResourceEndpoint,
    PersonaDTO,
    PersonaFilter,
    PersonaResourceEndpoint,
    TipoProduccion1, TipoProduccion2,
} from 'app/apina/apina-api';
import {Dictionary, FwBaseList, FwPaginacionComponent, Order, Page, Pageable} from 'fw-core';
import {FotografiaUtilService} from 'app/shared/util/fotografia-util.service';
import {MAX_SEARCH_YEAR, MIN_SEARCH_YEAR} from 'app/constants';
import {Options} from '@angular-slider/ngx-slider';
import {ActivatedRoute} from '@angular/router';
import {ContenidoModal} from "app/public/home/home.component";
import {ModalObraComponent} from "app/public/modal-obra/modal-obra.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-grid-fotografias',
    templateUrl: './grid-fotografias.component.html',
    styleUrls: ['./grid-fotografias.component.scss']
})
export class GridFotografiasComponent extends FwBaseList implements OnInit {
    fotografias: FotografiaDTO[];
    totalItems: number;
    private pagina: number;
    orden: Order[];
    isDetached = false;
    initialModoFilterCopy: ModoFilter;
    initialPeriodoHistoricoFilter: PeriodoHistoricoFilter;
    initialPersonaFilter: PersonaFilter;
    modos: ModoDTO[];
    periodosHistoricos: any[];
    arquitectas: { id: string, name: string }[];
    fotografas: { id: string, name: string }[];
    tiposProduccion1Dict: Dictionary<any>;
    tiposProduccion1: { key: string, name: string } [];
    tiposProduccion2Dict: Dictionary<any>;
    tiposProduccion2: { key: string, name: string } [];
    comunidadesAutonomasDict: Dictionary<any>;
    comunidadesAutonomas: { key: string, name: string } [];
    estaus: Dictionary<any>;
    contadorArquitectas = 0;
    contadorObras = 0;
    contadorFotografas = 0;
    obraDTO: ModalObraDTO;
    pageSize = 16;
    contadoresWidth = '';
    contadoresWidthNumber = 0;

    @ViewChild(FwPaginacionComponent, {static: true}) enxePaginacion: FwPaginacionComponent;
    // ngx-slider
    sliderOptions: Options = {
        floor: MIN_SEARCH_YEAR,
        ceil: MAX_SEARCH_YEAR,
        minLimit: MIN_SEARCH_YEAR,
        maxLimit: MAX_SEARCH_YEAR,
        noSwitching: true
    };
    fechaSlider = {
        minValue: MIN_SEARCH_YEAR,
        maxValue: MAX_SEARCH_YEAR
    };

    fotografiaFiltro: FotografiaFilter = {
        clavesPeriodoHistorico: null as string[],
        tiposProduccion1: null as TipoProduccion1[],
        tiposProduccion2: null as TipoProduccion2[],
        fechaInicioInferior: null as string,
        fechaInicioSuperior: null as string,
        idArquitecta: null as number,
        idFotografa: null as number,
        modo: null as ModoDTO,
        comunidadAutonoma: null as ComunidadAutonoma
    };

    isCollapsed = false;
    formatter = (e: { nombre: string }) => e.nombre;

    constructor(
        private route: ActivatedRoute,
        private fotografiaResource: FotografiaResourceEndpoint,
        private fotografiaUtils: FotografiaUtilService,
        private modoResource: ModoResourceEndpoint,
        private periodoHistoricoResource: PeriodoHistoricoResourceEndpoint,
        private personaResource: PersonaResourceEndpoint,
        private modalService: NgbModal,
        private obraResource: ObraResourceEndpoint
    ) {
        super();
    }

    ngOnInit(): void {
        this.tiposProduccion1Dict = this.route.snapshot.data.tiposProduccion;
        this.tiposProduccion1 = this.dictionaryToArray(this.tiposProduccion1Dict);
        this.tiposProduccion2Dict = this.route.snapshot.data.tiposProduccion2;
        this.tiposProduccion2 = this.dictionaryToArray(this.tiposProduccion2Dict);
        this.comunidadesAutonomasDict = this.route.snapshot.data.comunidadesAutonomas;
        this.comunidadesAutonomas = this.dictionaryToArray(this.comunidadesAutonomasDict);
        this.estaus = this.route.snapshot.data.estatus;
        this.subs.add(this.modoResource.findAll(this.initialModoFilterCopy)
            .subscribe((res: any) => {
                this.modos = res;
                this.modos.sort((value1, value2) => value1.nombre.localeCompare(value2.nombre));
            }));
        this.subs.add(this.periodoHistoricoResource.findAll(this.initialPeriodoHistoricoFilter)
            .subscribe((res: any[]) => {
                res.forEach(e => e.nombreAnos = e.nombre + ' (' + e.inicio + '-' + e.fin + ')');
                this.periodosHistoricos = res;
            }));
        this.subs.add(this.personaResource.findAllFotografas(this.initialPersonaFilter)
            .subscribe((res: PersonaDTO[]) => {
                this.fotografas = this.personaDTOToArray(res);
            }));
        this.subs.add(this.personaResource.findAllArquitectas(this.initialPersonaFilter)
            .subscribe((res: PersonaDTO[]) => {
                this.arquitectas = this.personaDTOToArray(res);
            }));

    }

    loadAll([pageable, filtro]: [Pageable, any]) {
        this.pagina = pageable.page;
        this.fotografiaResource.findAll(pageable, filtro).subscribe((res: Page) => {
            this.updateTotalItems(res.totalElements);
            this.fotografias = res.content;
        });
        this.fotografiaResource.getContadores(filtro).subscribe((res: ContadoresDTO) => {
            this.updateContadores(res);
        });
    }

    onScrollDown() {
        if (this.fotografias.length < this.totalItems) {
            this.pagina++;
            this.fotografiaResource.findAll(new Pageable(this.pagina, this.pageSize), this.fotografiaFiltro).subscribe(res => {
                this.updateTotalItems(res.totalElements);
                this.fotografias = this.fotografias.concat(res.content);
            });
        } else {
            this.isDetached = true;
        }
    }

    getFotografia(fotografia: FotografiaDTO) {
        return this.fotografiaUtils.getFotografia(fotografia);
    }

    // Filtros
    resetSliderOptions(): void {
        this.fechaSlider.minValue = MIN_SEARCH_YEAR;
        this.fechaSlider.maxValue = MAX_SEARCH_YEAR;
        const newOptions: Options = Object.assign({}, this.sliderOptions);
        newOptions.minLimit = MIN_SEARCH_YEAR;
        newOptions.maxLimit = MAX_SEARCH_YEAR;
        this.sliderOptions = newOptions;
    }

    removeFilters(): void {
        this.fotografiaFiltro = {
            clavesPeriodoHistorico: null as string[],
            tiposProduccion1: null as TipoProduccion1[],
            tiposProduccion2: null as TipoProduccion2[],
            fechaInicioInferior: null as string,
            fechaInicioSuperior: null as string,
            idArquitecta: null as number,
            idFotografa: null as number,
            modo: null as ModoDTO,
            comunidadAutonoma: null as ComunidadAutonoma
        };
        this.resetSliderOptions();
        this.applyFilters();
    }

    applyFilters(): void {
        this.fotografiaFiltro.fechaInicioInferior = this.fechaSlider.minValue.toString();
        this.fotografiaFiltro.fechaInicioSuperior = this.fechaSlider.maxValue.toString();
        this.pagina = 0;
        this.fotografiaResource.findAll(new Pageable(this.pagina, this.pageSize), this.fotografiaFiltro).subscribe(res => {
            this.updateTotalItems(res.totalElements);
            this.fotografias = res.content;
        });
        this.fotografiaResource.getContadores(this.fotografiaFiltro).subscribe((res: ContadoresDTO) => {
            this.updateContadores(res);
        });
        this.isDetached = false;
    }

    updateAndApplyFiltersPeriodoHistorico(): void {
        if (this.fotografiaFiltro.clavesPeriodoHistorico.length > 0) {
            const periodosHistoricos = this.getPeriodosHistoricos(this.fotografiaFiltro.clavesPeriodoHistorico);
            const inicioArray = periodosHistoricos.map(e => parseInt(e.inicio, 10));
            const inicioInt = Math.min(...inicioArray);
            const finArray = periodosHistoricos.map(e => parseInt(e.fin, 10));
            const finInt = Math.max(...finArray);
            this.fechaSlider.minValue = inicioInt;
            this.fechaSlider.maxValue = finInt;

            const newOptions: Options = Object.assign({}, this.sliderOptions);
            newOptions.minLimit = inicioInt;
            newOptions.maxLimit = finInt;
            this.sliderOptions = newOptions;
            //Cambiar color ng-slider-selection
        } else {
            this.resetSliderOptions();
        }
        this.applyFilters();
    }

    openModal(obraId: number) {
        this.obraResource.obtenerModal(obraId).subscribe(res => {
            this.obraDTO = res;
            const modal = this.modalService.open(ModalObraComponent, {
                windowClass: 'my-modal',
                size: 'lg',
                keyboard: true,
                centered: true
            });
            modal.componentInstance.contenidoModal = this.getContenidoModal(this.obraDTO);
        });
    }

    // METODOS PRIVADOS

    private alphabeticComparator(value1, value2) {
        //Poner OTROS al final
        if (value1.key === 'OTROS') {
            return true;
        }
        if (value2.key === 'OTROS') {
            return false;
        }
        return value1.name.localeCompare(value2.name);
    }

    private dictionaryToArray(dict: Dictionary<any>): { key: string, name: string } [] {
        const result = [];
        for (const key in dict) {
            if (dict.hasOwnProperty(key)) {
                const name = dict[key];
                result.push({key, name});
            }
        }
        result.sort(this.alphabeticComparator);
        return result;
    }

    private personaDTOToArray(personaDTOS: PersonaDTO[]): { id: string, name: string } [] {
        const result = [];

        function pushToArray(e) {
            const id = e.id;
            const name = e.apellidos + ', ' + e.nombre;
            result.push({id, name});
        }

        personaDTOS.forEach(e => pushToArray(e));
        result.sort(this.alphabeticComparator);
        return result;
    }

    private escapeUndefined(e) {
        return e ? e : '';
    }

    private processReconocimiento(current) {
        let result = current.nombre ?? '';
        if (current.puesto) {
            result += ' ' + current.puesto;
        }
        if (current.categoria) {
            result += ' en la categoria de ' + current.categoria;
        }
        return result !== '' ? '- ' + result + '. <br>' : '';
    }

    private processReferencias(current) {
        const result = current ?? '';
        return result !== '' ? '- ' + result + '.<br>' : '';
    }

    private getContenidoModal(content: ModalObraDTO): ContenidoModal {
        const fotografias = content.fotografias ? content.fotografias.map(e => e as FotografiaDTO).sort(this.fotografiasComparator) : null;
        const fotografa = content.fotografias ? content.fotografias[0].fotografa.nombre + ' ' + content.fotografias[0].fotografa.apellidos : '';
        const arquitectas = content.autoras.reduce((accumulator, currentvalue) => accumulator + currentvalue.nombre.concat(' ' + currentvalue.apellidos), '');
        const referencias = content.publicaciones ? content.publicaciones.reduce((accumulator, currentvalue) => accumulator +
            this.processReferencias(currentvalue.referencia), '') : '';
        const reconocimientos = content.concursos ? content.concursos.reduce((accumulator, currentvalue) => accumulator +
            this.processReconocimiento(currentvalue), '') : '';
        const fechaInicio = this.escapeUndefined(content.fechaInicio);
        const fechaFin = this.escapeUndefined(content.fechaFin);
        const equipoDeTrabajo = this.escapeUndefined(content.modo.nombre);
        const colaboradores = this.escapeUndefined(content.colaboradores);
        const textoCritico = content.textoCritico;
        const estatus = this.estaus[content.estatus];
        const tipoProduccion1 = this.tiposProduccion1Dict[content.tipoProduccion1];
        const direccion = content.direccion;
        const comunidadAutonoma = this.comunidadesAutonomasDict[content.comunidadAutonoma];
        const periodoHistorico = content.periodoHistorico.nombre + ' ' + content.periodoHistorico.inicio + '-' + content.periodoHistorico.fin;
        const nombre = content.nombre;
        return {
            fotografias,
            fotografa,
            textoCritico,
            reconocimientos,
            referencias,
            estatus,
            tipoProduccion1,
            colaboradores,
            direccion,
            comunidadAutonoma,
            equipoDeTrabajo,
            fechaFin,
            fechaInicio,
            periodoHistorico,
            nombre,
            arquitectas,
        };
    }

    private fotografiasComparator(value1, value2) {
        return value1.fotografia.name.localeCompare(value2.fotografia.name);
    }

    private getPeriodosHistoricos(claves: string []): PeriodoHistoricoDTO [] {
        return this.periodosHistoricos.filter(periodoHistorico => claves.includes(periodoHistorico.clave));
    }

    private updateTotalItems(newTotalItems: number) {
        this.totalItems = newTotalItems;
        this.updateContadoresWidth();
    }

    private updateContadores(newContadores: ContadoresDTO) {
        this.contadorFotografas = newContadores.fotografas;
        this.contadorObras = newContadores.obras;
        this.contadorArquitectas = newContadores.arquitectas;
        this.updateContadoresWidth();
    }

    private updateContadoresWidth() {
        const maxNumber = Math.max(this.contadorFotografas, this.contadorFotografas, this.contadorArquitectas, this.totalItems);
        const newLength = maxNumber.toString().length;
        if (newLength !== this.contadoresWidthNumber) {
            this.contadoresWidthNumber = newLength;
            this.contadoresWidth = this.contadoresWidthNumber + 'rem';
        }
    }

}
